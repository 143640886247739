<template>
  <nav id="mainnav_mobile" class="mtxtblock">
    <ul class="mainnav_mobile clearfix">
      <li class="mobile_nav" v-if="nav.topnav">
        <ul class="mobile_topnav_1">
          <li v-for="item in nav.topnav" class="mobile_topnav_1_li">
            <a
              class="mobile_topnav_1_link"
              :id="$root.linkId('mobile_topnav_1_',item)"
              :href="$root.linkHref(item)" :target="$root.linkTarget(item)"
            >
              <span>{{item.l}}</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="mobile_nav" v-if="nav.ourCompany">
        <ul class="mobile_unserbuero_1">
          <li
            class="mobile_unserreisebuero_1_li haschild"
            :class="{open:navOpened.includes(nav.ourCompany),close:!navOpened.includes(nav.ourCompany)}"
            @click="navOpen(nav.ourCompany,'unserreisebuero')"
            ref="unserreisebuero"
          >
            <span class="pointer mob_action"></span>
            <a class="mobile_unserbuero_1_link" id="mobile_unserbuero_1_mehr_reisen" href="javascript:void(0)">
              <span>{{ nav.ourCompany.l }}</span>
            </a>
            <ul class="mobile_unserbuero_2" v-if="nav.ourCompany.c">
              <li v-for="item in nav.ourCompany.c" class="mobile_unserbuero_2_li">
                <a class="mobile_unserbuero_2_link"
                   :id="$root.linkId('mobile_unserbuero_2_',item)"
                   :href="$root.linkHref(item)"
                   :target="$root.linkTarget(item)">
                  <span>{{ item.l }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="mobile_nav" v-if="nav.themenav">
        <ul class="mobile_themenav_1">
          <li v-for="item in nav.themenav" class="mobile_themenav_1_li">
            <a
              class="mobile_topnav_1_link"
              :id="$root.linkId('mobile_themenav_1_',item)"
              :href="$root.linkHref(item)"
              :target="$root.linkTarget(item)"
            >
              <span>{{item.l}}</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="mobile_nav" v-if="nav.thememore">
        <ul class="mobile_moretheme_1">
          <li
            class="mobile_moretheme_1_li haschild"
            :class="{open:navOpened.includes(nav.thememore),close:!navOpened.includes(nav.thememore)}"
            @click="navOpen(nav.thememore,'moretheme')"
            ref="moretheme"
          >
            <span class="pointer mob_action"></span>
            <a class="mobile_moretheme_1_link" id="mobile_moretheme_1_mehr_reisen" href="javascript:void(0)">
              <span>{{ nav.thememore.l }}</span>
            </a>
            <ul class="mobile_moretheme_2" v-if="nav.thememore.c">
              <li v-for="item in nav.thememore.c" class="mobile_moretheme_2_li">
                <a class="mobile_moretheme_2_link"
                   :id="$root.linkId('mobile_moretheme_2_',item)"
                   :href="$root.linkHref(item)"
                   :target="$root.linkTarget(item)">
                  <span>{{ item.l }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="mobile_nav" v-if="nav.service">
        <ul class="mobile_service_1">
          <li
            class="mobile_service_1_li haschild"
            :class="{open:navOpened.includes(nav.service),close:!navOpened.includes(nav.service)}"
            @click="navOpen(nav.service,'service')"
            ref="service"
          >
            <span class="pointer mob_action"></span>
            <a class="mobile_service_1_link" id="mobile_service_1_reise-service" href="javascript:void(0)">
              <span>{{ nav.service.l }}</span>
            </a>
            <ul class="mobile_service_2" v-if="nav.service.c">
              <li v-for="item in nav.service.c" class="mobile_service_2_li">
                <a class="mobile_service_2_link"
                   :id="$root.linkId('mobile_service_2_',item)"
                   :href="$root.linkHref(item)"
                   :target="$root.linkTarget(item)">
                  <span>{{ item.l }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="mobile_nav" v-if="nav.about">
        <ul class="mobile_about_1">
          <li
            class="mobile_about_1_li haschild"
            :class="{open:navOpened.includes(nav.about),close:!navOpened.includes(nav.about)}"
            @click="navOpen(nav.about,'about')"
            ref="about"
          >
            <span class="pointer mob_action"></span>
            <a class="mobile_about_1_link" id="mobile_about_1_ueber_derpart" href="javascript:void(0)">
              <span>{{ nav.about.l }}</span>
            </a>
            <ul class="mobile_about_2" v-if="nav.about.c">
              <li v-for="item in nav.about.c" class="mobile_about_2_li">
                <a class="mobile_about_2_link"
                   :id="$root.linkId('mobile_about_2_',item)"
                   :href="$root.linkHref(item)"
                   :target="$root.linkTarget(item)">
                  <span>{{ item.l }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="mobile_nav" v-if="nav.contact">
        <ul class="mobile_contact_1">
          <li v-for="item in nav.contact" class="mobile_contact_1_li">
            <a class="mobile_contact_1_link"
               :id="$root.linkId('mobile_contact_1_',item)"
               @click="$root.openIra(item.cP)"
               :href="$root.linkHref(item)"
               :target="$root.linkTarget(item)">
              <span>{{ item.l }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "navigation-mobile",
  props:{
    nav:{
      type:Object,
      required: true,
    }
  },
  data(){
    return {
      navOpened:[],
    }
  },
  methods:{
    navOpen(item,target){
      if (this.navOpened.includes(item)){
        this.navOpened=this.navOpened.filter(i=>{
          return i!==item
        })
      }else{
        this.navOpened.push(item)
        this.$smoothScroll({
          scrollTo: this.$refs[target].offsetTop,
          duration: 400,
        })
      }
    },
  }
}
</script>

<style scoped>

</style>