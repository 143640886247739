export default {
	_frontend:null,
	_main:null,
	_content:null,
	_summary:null,
	_summaryWrap:null,
	_stepList:null,
	_breakHandle:false,
	_icon:null,
	_formular:null,
	_timeCal:null,
	_time:null,
	_timeIcon:null,
	_timeFrom:null,
	_timeTo:null,
	_timeMin:null,
	_timeError:null,
	_timeDel:null,
	_contact:null,
	_contactSearch:null,
	_searchResult:null,
	_contactStage:null,
	_contactContent:null,
	_contactContentWrap:null,
	_flyoutregistry:[],
	_sid:null,
	_vars:{
		ready:false,
		compatmode:null,
		scrollTop:0,
		steps:{},
		stepsstart:{
			theme:1,
			dest:1,
			data:1,
			contact:1,
			contactdata:1
		},
		iraDestCount:0,
		back:'',
		step:'',
		mainurl:'',
		url:'',
		urlContct:'',
		urlCaller:'',
		titleCaller:'',
		checkHistory:0,
		calendarConfig:{
			firstDay: 1,
			altFormat: "dd.mm.yy",
			minDate:4,
			defaultDate:null,
			hideIfNoPrevNext:true,
			setDate:null,
			prevText: '&nbsp;&nbsp;',
			prevStatus: '',
		    prevJumpText: '&#x3c;&#x3c;',
		    prevJumpStatus: '',
		    nextText: '&nbsp;&nbsp;', nextStatus: '',
		    nextJumpText: '&#x3e;&#x3e;', nextJumpStatus: '',
		    currentText: 'heute', currentStatus: '',
		    todayText: 'heute', todayStatus: '',
		    clearText: '-', clearStatus: '',
		    closeText: 'X', closeStatus: '',
		    monthNames: ['Januar','Februar','März','April','Mai','Juni',
		    'Juli','August','September','Oktober','November','Dezember'],
		    monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun',
		    'Jul','Aug','Sep','Okt','Nov','Dez'],
		    dayNames: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
		    dayNamesShort: ['So','Mo','Di','Mi','Do','Fr','Sa'],
		    dayNamesMin: ['So','Mo','Di','Mi','Do','Fr','Sa']
		},
		themetags:null,
		desttags:null
	},
	_actions:{
		calOver:false,
		contactSearch:{},
		openClick:false
	},
	_calendarConfig:function(options){
		return $.extend({}, ira._vars.calendarConfig, options);
	},
	_form:{},
	_formstart:{
		sidebar : '',
		aid : null,
		mid : null,
		jump: false,
		summaryset:false,
		urlCaller:'',
		titleCaller:'',
		channel:'default',
		theme:{
			Reisethema:'',
			Besondere_Wuensche:''
		},
		dest:{
			Reiseziel:['']
		},
		data:{
			Zeitraum:'',
			ZeitraumVon:null,
			ZeitraumBis:null,
			Reisedauer:'',
			Erwachsene:'2 Erwachsene',
			Kinder:'',
			Alter:[],
			Anreise:'',
			Abflughafen:'',
			Unterkunft:'',
			Verpflegung:'',
			Zimmertyp:'',
			Sterne:'3',
			Reisedaten_Besondere_Wuensche:'',
			Preis_von:0,
			Preis_bis:10000
		},
		contact:{
			search:'',
			contactChannel:'email',
			Salutation:'',
			iraVorname:'',
			iraNachname:'',
			iraEmail:'',
			iraDatenschutzhinweis:'',
			iraMailCopy:'',
			iraNewsletter:'',
			iraPhoneno:'',
			iraCbTime: '',
			iraOffice:{
				id  : '',
				name: '',
				zip : '',
				city: '',
				contactEmail : ''
			}
		}
	},
	_formAnimation : function(){
		var myform = $('#iraFormular');

		if(window.deviceInfo.viewportMobile) {
			ira._formular.css({
				height:myform.height()
			});
		} else {
			ira._formular.animate({
				height:myform.height()
			});
		}
	},
	_contactSearchAction:function(){
		ira._contactSearch.suggestedSearchIra({
			minInput      : 3,
			keySlideBlock :	3,
			ajaxWait	  :	400,
			ajaxUrl 	  :	ira._vars.url,
			submitCallBack	:function(chosenData){
				ira._searchResult
					.addClass('active')
					.html(chosenData)
				;
				ira._form.contact.iraOffice = {
					address : $(chosenData).data('address').toString(),
					id  : $(chosenData).data('office').toString(),
					name: $(chosenData).data('name').toString(),
					zip : $(chosenData).data('zip').toString(),
					city: $(chosenData).data('city').toString(),
					contactEmail: $(chosenData).data('contactemail').toString(),
					html: chosenData.wrap('<div>').parent().html()
				};
				var getParam = {};
				if(ira._sid!=null){
					getParam.SID=ira._sid;
				}
				// Trigger event
				$.event.trigger({
                    'type': 'iraEvent.beforeAjax', 'function': '_contactSearchAction', 'value': null });
				$.get(ira._vars.mainurl+'/ira/info/mid/'+$(chosenData).data('office'),getParam, function(data){
					ira._form.sidebar = data.toString();
					ira._form.mid = $(chosenData).data('office');
					// Trigger event
					$.event.trigger({ 'type': 'iraEvent.afterAjax',
						'function': '_contactSearchAction', 'value': data });
				});
				$('li.none').removeClass('none');
				ira._formAnimation();
				ira._contactSearch.suggestedSearchIra('reset');
			}

		});
	},
	_iraflyout:function(obj,options,intent) {
		if(ira._vars.compatmode){
			ira._openContact(options);
		}else{
			var me = $(obj);
			var varoptions = options;
			var flyout = me.data('flyout');
			if(!flyout){
				flyout = $('<div class="ira_flyout" style="display:none" />').appendTo($('body'));
				var wrap = $('<div class="wrap" />').appendTo(flyout);
				var close = $('<span class="close">').appendTo(wrap);
				var ul = $('<ul />').appendTo(wrap);
				var wunschreise = $('<li class="anfrage"><span>Wunschreise anfragen</span></li>').appendTo(ul);
				var email = $('<li class="mail"><span>Nachricht schreiben</span></li>').appendTo(ul);
				var kontakt = $('<li class="service"><span>R&uuml;ckruf</span></li>').appendTo(ul);
				flyout.on('click',function(e){
					e.stopPropagation();
				});
				wunschreise.on('click',function(){
					ira._open(varoptions);
				});
				email.on('click',function(){
					ira._openContact(varoptions);
				});
				kontakt.on('click',function(){
					ira._openCallback(varoptions);
				});
				close.on('click',function(){
					ira._flyoutmanage();
				});

				me.data('flyout',flyout);
				ira._flyoutregistry.push(flyout);
			}
			var position = me.offset();
			var positiontop = position.top+30;
			var positionleft = position.left;

			if(obj.id == 'hira'){
				flyout.addClass('sticky');
			}else{
				flyout.removeClass('sticky');
			}

			if(intent!=undefined) {
				if(intent.left) {
					positionleft = positionleft + intent.left;
				}
				if(intent.top) {
					positiontop = positiontop + intent.top;
				}
			}

			flyout.css({
				top: positiontop,
				left: positionleft,
				right : '',
				margin : ''
			});
			if(!flyout.hasClass('sticky')){
				if(window.deviceInfo.viewportMobile===true){
					flyout.css({
						top: positiontop,
						left: 0,
						right: 0,
						margin : 'auto'
					});
				}
			}

			flyout.donothide=true;
			//ira._flyoutmanage();
		}
		return false;
	},
	_flyoutmanage:function(){
		for(var x=0;x<ira._flyoutregistry.length;x++){
			if(ira._flyoutregistry[x].donothide){
				if(window.deviceInfo.viewportMobile) {
					ira._flyoutregistry[x].show();
				} else {
					ira._flyoutregistry[x].fadeIn();
				}
				if(ira._onscreen(ira._flyoutregistry[x]) == false) {
					var pos = ira._flyoutregistry[x].offset();

					if(window.deviceInfo.viewportMobile) {
						$("html, body").scrollTop(pos['top']-(window.innerHeight-ira._flyoutregistry[x].height()));
					} else {
						$("html, body").animate({scrollTop:pos['top']-(window.innerHeight-ira._flyoutregistry[x].height())}, '500');
					}
				}
				delete ira._flyoutregistry[x].donothide;
			}else{
				ira._flyoutregistry[x].hide();
			}
		}
	},
	_onscreen:function(el) {
	    if (typeof jQuery === "function" && el instanceof jQuery) {
	        el = el[0];
	    }
	    var rect = el.getBoundingClientRect();
	    return (
	        rect.top >= 0 &&
	        rect.left >= 0 &&
	        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
	        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
	    );
	},
	_getCompatMode:function(){
		if(ira._vars.compatmode==null){
			var ua = navigator.userAgent.toLowerCase();
			if(ua.match(/MSIE 8/i) || ua.match(/MSIE 7/i)){
				ira._vars.compatmode = true;
			}else{
				ira._vars.compatmode = false;
			}
		}
		return ira._vars.compatmode;
	},
	_create:function(options){
		ira._getCompatMode();
		$(window).on('click',
			ira._flyoutmanage
		);
		$(window).resize(ira._flyoutmanage);

		$.ajaxSetup({
//			crossDomain : true,
			cache: false
		});
		ira._setVars(options);
		ira._frontend = $('#frontend');
		ira._main = $('#ira');
	},
	_init:function(){
		ira._vars.steps[ira._vars.step]=1;
		ira._historyBack();
		ira._stepList.find('.active').removeClass('active');
		$('#iraStep'+ira._vars.step).addClass('active');
		ira._icon.attr('id','iraIcon'+ira._vars.step);
		ira._formular.submit(function(e){
			e.preventDefault();
			e.stopPropagation();
			ira._submit();
		});
		$('#iraContact').html(ira._form.sidebar);
		/*load from storage*/
		var storage = $.jStorage.index();
		if(window.dev==1){
			if(storage.length > 0){
				debug(storage);
			}else{
				debug('storage ist leer');
				debug('storage verfügbar: '+$.jStorage.storageAvailable());
			}
		}
		//$.jStorage.flush();

		var contactChannel = ira._getSavedData("contactChannel");
		if(contactChannel!=null){
			ira._form.contact.contactChannel 	= contactChannel;
		}
		ira._form.contact.Salutation 		= ira._getSavedData("Salutation");
		ira._form.contact.iraEmail 			= ira._getSavedData("iraEmail");
		ira._form.contact.iraNachname 		= ira._getSavedData("iraNachname");
		ira._form.contact.iraPhoneno 		= ira._getSavedData("iraPhoneno");
		ira._form.contact.iraVorname 		= ira._getSavedData("iraVorname");
	},
	_setVars:function(options){
		ira._vars = $.extend({}, ira._vars, options);
	},
	_setSummary:function(html, callback){
		if(html!=ira._summaryWrap.html()){
			ira._summary.css({
				height:ira._summary.height(),
				overflow:'hidden'
			});

			if(window.deviceInfo.viewportMobile) {
				ira._summaryWrap.show(function(){
					ira._summaryWrap
						.html(html)
						.css({
							visibility:'hidden',
							display:'block'
						});
						ira._summary.css({
							height:ira._summaryWrap.outerHeight()
						});

					ira._summaryWrap
						.css({
							visibility:'visible',
							display:'none'
						})
						.show(function(){
							if($.isFunction(callback)){
								callback();
							}
							ira._summary.css({
								height:ira._summaryWrap.outerHeight()
							});
						});
				});
			} else {
				ira._summaryWrap.hide(function(){
					ira._summaryWrap
						.html(html)
						.css({
							visibility:'hidden',
							display:'block'
						});
						ira._summary.animate({
							height:ira._summaryWrap.outerHeight()
						});

					ira._summaryWrap
						.css({
							visibility:'visible',
							display:'none'
						})
						.fadeIn(function(){
							if($.isFunction(callback)){
								callback();
							}
							ira._summary.animate({
								height:ira._summaryWrap.outerHeight()
							});
						});
				});
			}
		}
	},
	_initContact:function(){
		var setheight = ira._contactContentWrap.height();
		if(window.deviceInfo.viewportMobile) {
			if(setheight<150){setheight=150;}
		} else {
			if(setheight<400){setheight=400;}
		}
		if(window.deviceInfo.viewportMobile) {
			ira._contactContent.css({
				height:setheight
			});
		} else {
			ira._contactContent.animate({
				height:setheight
			});
		}
		$(document).on('breakpoint', function(e){
			if(e.status == 'm'){
				ira._main.css('min-height', ira._main.data('docheight'));
			}else{
				ira._main.css('min-height', '100%');
			}
			if(window.deviceInfo.viewportMobile) {
				ira._contactContent.css({
					height: ira._contactContentWrap.height()
				});
			} else {
				ira._contactContent.animate({
					height: ira._contactContentWrap.height()
				});
			}
		});
	},
	_createContact:function(){
		var setheight = ira._contactContentWrap.height(true);
		if(window.deviceInfo.viewportMobile) {
			if(setheight<150){setheight=150;}
		} else {
			if(setheight<400){setheight=400;}
		}
		ira._contactContent.css({
			height:setheight
		});
		if(window.deviceInfo.viewportMobile) {
			ira._contactStage.css({
				visibility:'visible',
				display:'none'
			}).show();
		} else {
			ira._contactStage.css({
				visibility:'visible',
				display:'none'
			}).fadeIn(1000);
		}
	},
	_openContact:function(options){
		ira._flyoutmanage();
		if(ira._vars.ready && !ira._actions.openClick){
			ira._actions.openClick=true;
			ira._vars.scrollTop = $(window).scrollTop();
			ira._form = $.extend(true,{},ira._formstart);
			if(options){
				ira._form = $.extend(true, ira._form,options);
			}
			let request = $.extend(true,{start:1,type:'contact'}, options);
			let sid = sessionStorage.getItem('DpSid')
			if (sid){
				request.SID=sid
			}
			// Trigger event
			$.event.trigger({
				'type': 'iraEvent.beforeAjax', 'function': '_openContact', 'value': null });
			$.get(ira._vars.urlContact,request,function(c){
				if(window.deviceInfo.viewportMobile) {
					ira._main.show().html(c);
					ira._frontend.hide('slow',function(){
						ira._main.css('position','absolute');
						ira._actions.openClick=false;
						ira._handleHeightOnBreakpoint();
						$.scrollTo($('body'), 0);

					});
				} else {
					ira._main.fadeIn().html(c);
					ira._frontend.hide(function(){
						ira._main.css('position','absolute');
						ira._actions.openClick=false;
						ira._handleHeightOnBreakpoint();
						$.scrollTo($('body'), 0);
					});
				}
				// Trigger event
				$.event.trigger({ 'type': 'iraEvent.afterAjax',
					'function': '_openContact', 'value': c });
			});
			$(window).trigger('iraOpen');
		}
	},
	_openCallback:function(options){
		if(ira._vars.compatmode){
			ira._openContact(options);
		}else{
			ira._flyoutmanage();
			if(ira._vars.ready && !ira._actions.openClick){
				ira._actions.openClick=true;
				ira._vars.scrollTop = $(window).scrollTop();
				ira._form = $.extend(true,{},ira._formstart);
				if(options){
					ira._form = $.extend(true, ira._form,options);
				}
				let request = $.extend(true,{start:1,type:'callback'}, options);
				let sid = sessionStorage.getItem('DpSid')
				if (sid){
					request.SID=sid
				}
				// Trigger event
				$.event.trigger({
					'type': 'iraEvent.beforeAjax', 'function': '_openCallback', 'value': null });
				$.get(ira._vars.urlContact,request,function(c){
					if(window.deviceInfo.viewportMobile) {
						ira._main.show().html(c);
						ira._frontend.hide('slow',function(){
							ira._main.css('position','absolute');
							ira._actions.openClick=false;
							ira._handleHeightOnBreakpoint();
							$.scrollTo($('body'), 0);
						});
					} else {
						ira._main.fadeIn().html(c);
						ira._frontend.hide(function(){
							ira._main.css('position','absolute');
							ira._actions.openClick=false;
							ira._handleHeightOnBreakpoint();
							$.scrollTo($('body'), 0);
						});
					}
					// Trigger event
					$.event.trigger({ 'type': 'iraEvent.afterAjax',
						'function': '_openCallback', 'value': c });
				});
			}
		}
		$(window).trigger('iraOpen');
	},
	_submitContact:function(){
		if(!ira._actions.openClick){
			ira._actions.openClick=true;
			var myform = $('#iraLayerFormular');
			var url = myform.attr('action');
			if(ira._sid!=null){
				url+='&SID='+ira._sid;
			}

			// Trigger event
			$.event.trigger({
				'type': 'iraEvent.beforeAjax', 'function': '_submitContact', 'value': null });
			if(window.deviceInfo.viewportMobile) {
				myform.hide(function(){
					$.post(
						url,
						myform.serialize(),
						function(c){
							ira._formular.html(c);
							ira._actions.openClick=false;
							if(window.deviceInfo.viewportMobile == true){
								ira._main.css('min-height', $(document).height()).data('docheight', $(document).height());
								$(window).scrollTo(0, 100);
							}
							// Trigger event
							$.event.trigger({ 'type': 'iraEvent.afterAjax',
								'function': '_submitContact', 'value': c });
						}
					);
				});
			} else {
				myform.fadeOut(function(){
					$.post(
						url,
						myform.serialize(),
						function(c){
							ira._formular.html(c);
							ira._actions.openClick=false;
							if(window.deviceInfo.viewportMobile == true){
								ira._main.css('min-height', $(document).height()).data('docheight', $(document).height());
								$(window).scrollTo(0, 100);
							}
							// Trigger event
							$.event.trigger({ 'type': 'iraEvent.afterAjax',
								'function': '_submitContact', 'value': c });
						}
					);
				});
			}
		}
	},
	_handleHeightOnBreakpoint :  function(){
		var nHeight = $('#iraLayerWrap').outerHeight(true);
		if(window.deviceInfo.viewportMobile == true){
			ira._main.css('min-height', nHeight);
		}
		if(!ira._breakHandle){
			$(document).on('breakpoint', function(e){
				if(e.status == 'd'){
					ira._main.css({
						'min-height' : '',
						'height' : '100%'

					});
				}else{
					window.setTimeout(function(){
						$('#iraLayerContent').css('height', '');
						var nheight = ira._contactContent.outerHeight(true);
						if(nheight < $(document).height()){
							nheight = $(document).height();
						}
						ira._main.css('min-height', nheight).data('docheight', nheight);
					},150);


				}
			});
			ira._breakHandle = true;
		}
	},
	_open:function(options){
		var me=this;
		if(ira._vars.compatmode){
			ira._openContact(options);
		}else{
			ira._flyoutmanage();
			if(ira._vars.ready){
				ira._vars.scrollTop = $(window).scrollTop();
				ira._form = $.extend(true,{},ira._formstart);
				if(options){
					ira._form = $.extend(true, ira._form,options);
				}
				ira._form.urlCaller = ira._vars.urlCaller;
				ira._form.titleCaller = ira._vars.titleCaller;
				ira._vars.steps = $.extend(true,{}, ira._vars.stepsstart);

				if(options.mid==1000){delete options.mid;}

				let request = $.extend(true,{start:1}, options);
				let sid = sessionStorage.getItem('DpSid')
				if (sid){
					request.SID=sid
				}
				// Trigger event
				$.event.trigger({
					'type': 'iraEvent.beforeAjax', 'function': '_open', 'value': null });
				$.get(ira._vars.url,request,function(c){
					if(window.deviceInfo.viewportMobile) {
						ira._main.show().html(c);
						ira._frontend.hide('slow',function(){
							ira._main.css('position','absolute');
							$.scrollTo($('body'), 300);
						});
					} else {
						ira._main.fadeIn().html(c);
						ira._frontend.fadeOut(function(){
							ira._main.css('position','absolute');
							$.scrollTo($('body'), 300);
						});
					}
					// Trigger event
					$.event.trigger({ 'type': 'iraEvent.afterAjax',
						'function': '_open', 'value': c });

					$(window).trigger('iraOpen');
				});
			}
		}
	},
	_close:function(){
		ira._main.css('position','fixed');
		ira._frontend.show();
		$(window).scrollTop(ira._vars.scrollTop);
		if(window.deviceInfo.viewportMobile) {
			ira._main.hide();
		} else {
			ira._main.fadeOut('slow');
		}
		$(window).trigger('iraClose');
	},
	_newdest:function(){
		ira._vars.iraDestCount++;
		ira._newdestObject(ira._vars.iraDestCount);
		$('#iraDest'+ira._vars.iraDestCount).focus();
	},
	_newdestObject:function(id){
		return;
		// var objid = id;
		// var objli = $('<li class="iraDestLi"></li>');
		// var value = '';
		// if(ira._form.dest.Reiseziel[objid]!=undefined){
		// 	value = ira._form.dest.Reiseziel[objid];
		// }
		// var objinput = $('<input type="text" class="iraDest" id="iraDest'+objid+'" name="iraDest['+objid+']" value="'+value+'" />');
		// objinput
		// 	.on('change',function(){
		// 		var value = objinput.val();
		// 		if(value==''){
		// 			delete ira._form.dest.Reiseziel[objid];
		// 		}else{
		// 			ira._form.dest.Reiseziel[objid]=value;
		// 		}
		// 	});
		// objinput.appendTo(objli);
		// var objdel = $('<a class="del"></a>');
		// objdel.on('click',function(){
		// 	objli.remove();
		// 	delete ira._form.dest.Reiseziel[objid];
		// 	if(window.deviceInfo.viewportMobile) {
		// 		ira._formular.css({
		// 			height:$('#iraFormular').height()
		// 		},'fast');
		// 	} else {
		// 		ira._formular.animate({
		// 			height:$('#iraFormular').height()
		// 		},'fast');
		// 	}
		// });
		// objdel.appendTo(objli);
		// $('#iraStepDestUl').append(objli);
		// if(window.deviceInfo.viewportMobile) {
		// 	ira._formular.css({
		// 		height:$('#iraFormular').height()
		// 	},'fast');
		// } else {
		// 	ira._formular.animate({
		// 		height:$('#iraFormular').height()
		// 	},'fast');
		// }
	},
	_fromSummary: function(step){

		$('#iraSummary').removeClass('mb50');
		$('#iraStep, #iraContent').removeClass('none');
		if(window.deviceInfo.viewportMobile) {
			$('#iraForm').css({
				height : $('#iraFormular').height()
			});
		} else {
			$('#iraForm').animate({
				height : $('#iraFormular').height()
			});
		}
		ira._goto(step);
		$('#ajContent').remove();
	},
	_goto:function(step){
		if(
			step != ira._vars.step &&
			ira._vars.checkHistory==0 &&
			ira._vars.steps[step]==1
		){
			var url = ira._vars.url+'/step/'+step;
			var getParam = new Array();
			if(ira._sid!=null){
				getParam.push('SID='+ira._sid);
			}
			if(ira._hasValue(ira._form.aid)){
				getParam.push('aid='+ira._form.aid);
			}else if(ira._hasValue(ira._form.eid)){
				getParam.push('eid='+ira._form.eid);
			}else if(ira._hasValue(ira._form.mid)){
				getParam.push('mid='+ira._form.mid);
			}
			if(getParam.length>0){
				url+='?'+getParam.join('&');
			}
			ira._vars.checkHistory++;
			if(window.deviceInfo.viewportMobile) {
				$('#iraTimeCal').hide();
			} else {
				$('#iraTimeCal').fadeOut();
			}
			var myform = $('#iraFormular');
			ira._formular.css({
				height:myform.height()
			});

			// Trigger event
			$.event.trigger({
				'type': 'iraEvent.beforeAjax', 'function': '_goto', 'value': null });

			if(window.deviceInfo.viewportMobile) {
				myform.hide(function(){
					$.get(url,function(c){
						ira._formular.html(c);
						var myform = $('#iraFormular');
						ira._formular.css({
							height:myform.height()
						});
						ira._vars.checkHistory=0;
						$('#iraSummary').removeClass('mb50');
						$('#iraStep, #iraContent').removeClass('none');
						$.scrollTo($('body'), 300);
						// Trigger event
						$.event.trigger({ 'type': 'iraEvent.afterAjax',
							'function': '_goto', 'value': c });
					});
				});
			} else {
				myform.fadeOut(function(){
					$.get(url,function(c){
						ira._formular.html(c);
						var myform = $('#iraFormular');
						ira._formular.animate({
							height:myform.height()
						});
						ira._vars.checkHistory=0;
						$('#iraSummary').removeClass('mb50');
						$('#iraStep, #iraContent').removeClass('none');
						$.scrollTo($('body'), 300);
						// Trigger event
						$.event.trigger({ 'type': 'iraEvent.afterAjax',
							'function': '_goto', 'value': c });
					});
				});
			}
		}
	},
	_back:function(){
		if(ira._vars.checkHistory==0){
			ira._vars.checkHistory++;
			if(window.deviceInfo.viewportMobile) {
				$('#iraTimeCal').hide();
			} else {
				$('#iraTimeCal').fadeOut();
			}
			var myform = $('#iraFormular');
			ira._formular.css({
				height:myform.height()
			});
            // Trigger event
            $.event.trigger({
                'type': 'iraEvent.beforeAjax', 'function': '_back', 'value': null });
			if(window.deviceInfo.viewportMobile) {
				myform.hide(function(){
					var url = ira._vars.back;
					var getParam = new Array();
					if(ira._sid!=null){
						getParam.push('SID='+ira._sid);
					}
					if(ira._hasValue(ira._form.aid)){
						getParam.push('aid='+ira._form.aid);
					}else if(ira._hasValue(ira._form.eid)){
						getParam.push('eid='+ira._form.eid);
					}else if(ira._hasValue(ira._form.mid)){
						getParam.push('mid='+ira._form.mid);
					}
					if(getParam.length>0){
						url+='?'+getParam.join('&');
					}

					$.get(url,function(c){
						ira._formular.html(c);
						var myform = $('#iraFormular');
						ira._formular.css({
							height:myform.height()
						});
						ira._vars.checkHistory=0;
						$.scrollTo($('body'), 300);
						// Trigger event
						$.event.trigger({ 'type': 'iraEvent.afterAjax',
							'function': '_back', 'value': c });
					});
				});
			} else {
				myform.fadeOut(function(){
					var url = ira._vars.back;
					var getParam = new Array();
					if(ira._sid!=null){
						getParam.push('SID='+ira._sid);
					}
					if(ira._hasValue(ira._form.aid)){
						getParam.push('aid='+ira._form.aid);
					}else if(ira._hasValue(ira._form.eid)){
						getParam.push('eid='+ira._form.eid);
					}else if(ira._hasValue(ira._form.mid)){
						getParam.push('mid='+ira._form.mid);
					}
					if(getParam.length>0){
						url+='?'+getParam.join('&');
					}
					$.get(url,function(c){
						ira._formular.html(c);
						var myform = $('#iraFormular');
						ira._formular.animate({
							height:myform.height()
						});
						ira._vars.checkHistory=0;
						$.scrollTo($('body'), 300);
                        // Trigger event
                        $.event.trigger({ 'type': 'iraEvent.afterAjax',
                            'function': '_back', 'value': c });
					});
				});
			}
		}
	},
	_getSavedData : function(value){
		var data = $.jStorage.get(value);
		if(data){
			return data;
		}else{
			return null;
		}
	},
	_submit:function(){
		if(ira._vars.checkHistory==0){
			ira._vars.checkHistory++;
			if(window.deviceInfo.viewportMobile) {
				$('#iraTimeCal').hide();
			} else {
				$('#iraTimeCal').fadeOut();
			}
			var myform = $('#iraFormular');
			var url = myform.attr('action');
			if(ira._form.summaryset){
				url=ira._vars.url+'/step/summary';
			}
			var getParam = new Array();
			if(ira._sid!=null){
				getParam.push('SID='+ira._sid);
			}
			if(ira._hasValue(ira._form.aid)){
				getParam.push('aid='+ira._form.aid);
			}else if(ira._hasValue(ira._form.eid)){
				getParam.push('eid='+ira._form.eid);
			}else if(ira._hasValue(ira._form.mid)){
				getParam.push('mid='+ira._form.mid);
			}
			if(getParam.length>0){
				url+='?'+getParam.join('&');
			}
			ira._formular.css({
				height: myform.height()
			});
            // Trigger event
            $.event.trigger({
                'type': 'iraEvent.beforeAjax', 'function': '_submit', 'value': null });
			if(window.deviceInfo.viewportMobile) {
				myform.hide(function(){
					if(window.deviceInfo.viewportMobile){
						var pinpfro = $('#iraPriceFrom');
						var pinpto = $('#iraPriceTo');
						if(pinpfro.length > 0){
							ira._form.data.Preis_von = pinpfro.val().replace(',- €','').replace('.','');
						}
						if(pinpto.length > 0){
							ira._form.data.Preis_bis = pinpto.val().replace(',- €','').replace('.','');
						}
					}
					$.post(
						url,
						myform.serialize(),
						function(c){
							ira._formular.html(c);
							var myform = $('#iraFormular');
							ira._formular.css({
								height:myform.height()
							});
							ira._vars.checkHistory=0;
							$.scrollTo($('body'), 300);
                            // Trigger event
                            $.event.trigger({ 'type': 'iraEvent.afterAjax',
                                'function': '_submit', 'value': c });
						}
					);
				});
			} else {
				myform.fadeOut(function(){
					if(window.deviceInfo.viewportMobile){
						var pinpfro = $('#iraPriceFrom');
						var pinpto = $('#iraPriceTo');
						if(pinpfro.length > 0){
							ira._form.data.Preis_von = pinpfro.val().replace(',- €','').replace('.','');
						}
						if(pinpto.length > 0){
							ira._form.data.Preis_bis = pinpto.val().replace(',- €','').replace('.','');
						}
					}
					$.post(
						url,
						myform.serialize(),
						function(c){
							ira._formular.html(c);
							var myform = $('#iraFormular');
							ira._formular.animate({
								height:myform.height()
							});
							ira._vars.checkHistory=0;
							$.scrollTo($('body'), 300);
                            // Trigger event
                            $.event.trigger({ 'type': 'iraEvent.afterAjax',
                                'function': '_submit', 'value': c });
						}
					);
				});
			}
		}
	},
	_finalSubmit: function(){
		if(ira._vars.checkHistory==0){
			ira._vars.checkHistory++;
			var myform = $('#iraFormular');
			var url = myform.attr('action');
			var getParam = new Array();
			if(ira._sid!=null){
				getParam.push('SID='+ira._sid);
			}
			if(ira._hasValue(ira._form.aid)){
				getParam.push('aid='+ira._form.aid);
			}else if(ira._hasValue(ira._form.eid)){
				getParam.push('eid='+ira._form.eid);
			}else if(ira._hasValue(ira._form.mid)){
				getParam.push('mid='+ira._form.mid);
			}
			if(getParam.length>0){
				url+='?'+getParam.join('&');
			}
			ira._formular.css({
				height: myform.height()
			});
			ira._form.fsubmit = 1;
            // Trigger event
            $.event.trigger({
                'type': 'iraEvent.beforeAjax', 'function': '_finalSubmit', 'value': null });
			if(window.deviceInfo.viewportMobile) {
				myform.hide(function(){
					$.post(
						url,
						ira._form,
						function(c){
							$('#ajContent').html(c);
							var myform = $('#iraFormular');
							ira._formular.css({
								height:myform.height()
							});
							setTimeout ( function () {
								ira._summary.css("height","100%");
						    }, 1000);

							ira._vars.checkHistory=0;
							$.scrollTo($('body'), 300);
							$.jStorage.flush();
                            // Trigger event
                            $.event.trigger({ 'type': 'iraEvent.afterAjax',
                                'function': '_finalSubmit', 'value': c });
						}
					);
				});
			} else {
				myform.fadeOut(function(){
					$.post(
						url,
						ira._form,
						function(c){
							$('#ajContent').html(c);
							var myform = $('#iraFormular');
							ira._formular.animate({
								height:myform.height()
							});
							setTimeout ( function () {
								ira._summary.css("height","100%");
						    }, 1000);

							ira._vars.checkHistory=0;
							$.scrollTo($('body'), 300);
							$.jStorage.flush();
                            // Trigger event
                            $.event.trigger({ 'type': 'iraEvent.afterAjax',
                                'function': '_finalSubmit', 'value': c });
						}
					);
				});
			}
		}
	},
	_historyBack:function(){
		if (window.history && window.history.pushState) {
			window.history.pushState('forward', null, '#'+ira._vars.step);
			$(window).on('popstate', function() {
				if(ira._vars.back!=null){
					ira._back();
				}
			});
		}
	},
	_timeInit:function(){
		ira._timeTo.datepicker(
				ira._calendarConfig({
					onSelect: function(date,elem){ira._form.data.ZeitraumBis=date;}
				})
		).datepicker('setDate',ira._form.data.ZeitraumBis);
		if(ira._form.data.ZeitraumBis==null){
			ira._timeTo.find('.ui-datepicker-current-day').removeClass('ui-datepicker-current-day');
		}

		ira._timeFrom.datepicker(
			ira._calendarConfig({
				minDate:4,
				onSelect: function(date,elem){
					ira._form.data.ZeitraumVon=date;
					ira._timeTo.datepicker("option",'minDate',date);
					if(ira._form.data.ZeitraumBis==null){
						ira._timeTo.find('.ui-datepicker-current-day').removeClass('ui-datepicker-current-day');
					}
				}
			})
		);

		ira._timeMin=ira._timeFrom.datepicker('getDate');

		ira._timeFrom.datepicker('setDate',ira._form.data.ZeitraumVon);
		if(ira._form.data.ZeitraumVon==null){
			ira._timeFrom.find('.ui-datepicker-current-day').removeClass('ui-datepicker-current-day');
		}else{
			ira._timeTo.datepicker("option",'minDate',ira._timeFrom.datepicker('getDate'));
			if(ira._form.data.ZeitraumBis==null){
				ira._timeTo.find('.ui-datepicker-current-day').removeClass('ui-datepicker-current-day');
			}
		}

		if(ira._form.data.Zeitraum!=''){
			ira._time.val(ira._form.data.Zeitraum);
			ira._timeDel.show();
		}

		ira._time.on('click',function(e){e.stopPropagation();ira._timeCalOpen();});
		ira._timeIcon.on('click',function(e){e.stopPropagation();ira._timeCalOpen();});
		ira._timeDel.on('click',function(e){e.stopPropagation();ira._timeClear();});

		ira._timeCal.hover(
			function(){ira._actions.calOver=true;},
			function(){ira._actions.calOver=false;}
		);
		$(window).on('click',function(){
			if(!ira._actions.calOver){ira._timeCalClose();}
		});
	},
	_timeCalOpen:function(){
		if(window.deviceInfo.viewportMobile) {
			ira._timeCal.show();
		} else {
			ira._timeCal.fadeIn();
		}
	},
	_timeCalClose:function(){
		if(window.deviceInfo.viewportMobile) {
			ira._timeCal.hide();
		} else {
			ira._timeCal.fadeOut();
		}
	},
	_timeCalSubmit:function(){
		ira._timeError.fadeOut();
		var from = ira._timeFrom.datepicker('getDate');
		var to = ira._timeTo.datepicker('getDate');
		var fromDay = null;
		var fromMonth = null;
		var fromYear = null;
		var toDay = null;
		var toMonth = null;
		var toYear = null;
		if(from!=null || to!=null){
			var zrstring = '';
			if(from==null){
				zrstring += 'beliebig';
			}else{
				fromDay = from.getDate().toString();
				fromMonth = (from.getMonth() + 1).toString();
				fromYear = from.getFullYear().toString();

				if(fromDay.length === 1) {
					fromDay = '0' + fromDay;
				}

                if(fromMonth.length === 1) {
                    fromMonth = '0' + fromMonth;
                }

                zrstring += fromDay + '.' + fromMonth + '.' + fromYear;
				// zrstring += from.format('dd.mm.yyyy');
			}
			if(to!=null){
                toDay = to.getDate().toString();
                toMonth = (to.getMonth() + 1).toString();
                toYear = to.getFullYear().toString();

                if(toDay.length === 1) {
                    toDay = '0' + toDay;
                }

                if(toMonth.length === 1) {
                    toMonth = '0' + toMonth;
                }

                zrstring += ' - ' + toDay + '.' + toMonth + '.' + toYear;
				// zrstring += ' - '+to.format('dd.mm.yyyy');
			}
			if(from==null && to==null){
				zrstring = '';
			}
			ira._form.data.Zeitraum = zrstring;
			ira._time.val(ira._form.data.Zeitraum);
			ira._timeDel.show();
			ira._timeCalClose();
		}else{
			ira._timeError.fadeIn();
		}
	},
	_timeClear:function(){
		ira._form.data.Zeitraum='';
		ira._form.data.ZeitraumVon=null;
		ira._form.data.ZeitraumBis=null;
		ira._time.val(ira._form.data.Zeitraum);
		ira._timeFrom.datepicker('setDate',null).find('.ui-datepicker-current-day').removeClass('ui-datepicker-current-day');
		ira._timeTo
			.datepicker('setDate',null)
			.datepicker("option",'minDate',ira._timeMin)
			.find('.ui-datepicker-current-day')
			.removeClass('ui-datepicker-current-day');
		ira._timeDel.hide();
	},
	_hasValue : function(value, debugOut){
		value = $.trim(value);

		if(value == ""){
			if(debugOut===true){
				debug('empty');
			}
			return false;
		}else if(value == undefined){
			if(debugOut===true){
				debug('undefined');
			}
			return false;
		}else if(value == null){
			if(debugOut===true){
				debug('null');
			}
			return false;
		}
		if(debugOut===true){
			debug('value is: '+value+'!');
		}
		return true;
	},
	_getFormInfoForSummary : function(value, returnText){
		if(ira._hasValue(value)){
			return value;
		}else{
			return returnText;
		}
	},
	number_format:function (number, decimals, dec_point, thousands_sep) {
		number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
		var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			s = '',
			toFixedFix = function(n, prec) {
				var k = Math.pow(10, prec);
				return '' + (Math.round(n * k) / k)
					.toFixed(prec);
		};
		// Fix for IE parseFloat(0.55).toFixed(0) = 0;
		s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}
		return s.join(dec);
	},
	//summary-funktionen
	_writeThemeSummary: function(hideEditButton){
		var editButton = '';
		var endValue = ira._form.theme.Reisethema;
		if(ira._form.theme.Reisethema.length > 1) {
			endValue = ira._form.theme.Reisethema.join(', ');
		}
		if(hideEditButton==undefined){
			editButton = '<li class="clearfix ar">'+
			'<a class="btngraysmall navright" href="javascript: ira._fromSummary(\'theme\')"><span>Bearbeiten</span></a>'+
			'</li>';
		}
		var html = '<div class="sumElm clearfix" id="summaryTheme">'+
						'<div class="icon">'+
						'<span id="iraIcontheme"></span>'+
					'</div>'+
					'<div class="sumElmContent clearfix">'+
						'<ul>'+
							'<li class="clearfix">'+
								'<p class="sumHl">Was erleben?</p>'+
							'</li>'+
							'<li class="list clearfix mb">'+
								'<p>'+ira._getFormInfoForSummary(endValue,'Beliebiges Reisethema')+'</p>'+
							'</li>';
							if(ira._hasValue(ira._form.theme.Besondere_Wuensche)){
								html+=	'<li class="special clearfix">'+
											'<p><b>Besondere W&uuml;nsche:</b></p>'+
										'</li>'+
										'<li class="clearfix">'+
											'<p>'+ira._nl2br(ira._form.theme.Besondere_Wuensche)+'</p>'+
										'</li>';
							}

		     html +=		editButton+
						'</ul>'+
					'</div>'+
				'</div>';
		return html;
	},
	_writeDestSummary: function(hideEditButton){
		var editButton = '';
		if(hideEditButton==undefined){
			editButton = '<li class="clearfix ar">'+
			'<a class="btngraysmall navright" href="javascript: ira._fromSummary(\'dest\')"><span>Bearbeiten</span></a>'+
			'</li>';
		}
		var html = '<div class="sumElm clearfix" id="summaryDest">'+
						'<div class="icon">'+
						'<span id="iraIcondest"></span>'+
					'</div>'+
					'<div class="sumElmContent clearfix">'+
						'<ul>'+
							'<li class="clearfix">'+
								'<p class="sumHl">Wohin reisen?</p>'+
							'</li>'+
							'<li class="list clearfix">';
								if(ira._form.dest.Reiseziel[0].length>0){
									html += '<p>';
									for(var i=0; i<ira._form.dest.Reiseziel.length; i++){
										html += ira._form.dest.Reiseziel[i];
										if(i<ira._form.dest.Reiseziel.length-1) {html += ', ';}
									}
									html += '</p>';
								}else{
									html += '<p>Beliebiges Reiseziel</p>';
								}
		    html +=			'</li>'+
		    				editButton+
						'</ul>'+
					'</div>'+
				'</div>';
		return html;
	},
	_writeDataSummary: function(hideEditButton){
		var editButton = '';
		if(hideEditButton==undefined){
			editButton = '<li class="clearfix ar">'+
			'<a class="btngraysmall navright" href="javascript: ira._fromSummary(\'data\')"><span>Bearbeiten</span></a>'+
			'</li>';
		}
		var html = '<div class="sumElm clearfix" id="summaryData">'+
						'<div class="icon">'+
						'<span id="iraIcondata"></span>'+
					'</div>'+
					'<div class="sumElmContent clearfix">'+
						'<ul>'+
							'<li class="clearfix">'+
								'<p class="sumHl">Wann, mit wem und wie verreisen?</p>'+
							'</li>'+
							'<li class="clearfix"><p class="field">Zeitraum</p>';

								if(!ira._hasValue(ira._form.data.Zeitraum) && !ira._hasValue(ira._form.data.Reisedauer)){
									html +=	'<p class="data">beliebig</p>';
								}else if(ira._hasValue(ira._form.data.Zeitraum) && !ira._hasValue(ira._form.data.Reisedauer)){
									html +=	'<p class="data">'+ira._form.data.Zeitraum+'</p>';
								}else if(!ira._hasValue(ira._form.data.Zeitraum) && ira._hasValue(ira._form.data.Reisedauer)){
									html +=	'<p class="data">beliebig / '+ira._form.data.Reisedauer+'</p>';
								} if(ira._hasValue(ira._form.data.Zeitraum) && ira._hasValue(ira._form.data.Reisedauer)){
									html +=	'<p class="data">'+ira._form.data.Zeitraum+' / '+ira._form.data.Reisedauer+'</p>';
								}

//								if(!ira._hasValue(ira._form.data.ZeitraumVon) && !ira._hasValue(ira._form.data.ZeitraumBis) && !ira._hasValue(ira._form.data.Reisedauer)){
//									html +=	'<p class="data">beliebig</p>';
//								}else if(!ira._hasValue(ira._form.data.ZeitraumVon) && !ira._hasValue(ira._form.data.ZeitraumBis) && ira._hasValue(ira._form.data.Reisedauer)){
//									html +=	'<p class="data">beliebig / '+ira._form.data.Reisedauer+'</p>';
//								}else if(!ira._hasValue(ira._form.data.ZeitraumVon) && ira._hasValue(ira._form.data.ZeitraumBis) && ira._hasValue(ira._form.data.Reisedauer)){
//									html +=	'<p class="data">beliebig - '+ira._form.data.ZeitraumBis+' / '+ira._form.data.Reisedauer+'</p>';
//								}else if(ira._hasValue(ira._form.data.ZeitraumVon) && !ira._hasValue(ira._form.data.ZeitraumBis) && ira._hasValue(ira._form.data.Reisedauer)){
//									html +=	'<p class="data">'+ira._form.data.ZeitraumVon+' - beliebig / '+ira._form.data.Reisedauer+'</p>';
//								}else if(ira._hasValue(ira._form.data.ZeitraumVon) && ira._hasValue(ira._form.data.ZeitraumBis) && ira._hasValue(ira._form.data.Reisedauer)){
//									html +=	'<p class="data">'+ira._form.data.Zeitraum+' / '+ira._form.data.Reisedauer+'</p>';
//								}else if(ira._hasValue(ira._form.data.ZeitraumVon) && !ira._hasValue(ira._form.data.ZeitraumBis) && !ira._hasValue(ira._form.data.Reisedauer)){
//									html +=	'<p class="data">'+ira._form.data.Zeitraum+'</p>';
//								}else if(ira._hasValue(ira._form.data.ZeitraumVon) && ira._hasValue(ira._form.data.ZeitraumBis) && !ira._hasValue(ira._form.data.Reisedauer)){
//									html +=	'<p class="data">'+ira._form.data.Zeitraum+'</p>';
//								}else if(!ira._hasValue(ira._form.data.ZeitraumVon) && ira._hasValue(ira._form.data.ZeitraumBis) && !ira._hasValue(ira._form.data.Reisedauer)){
//									html +=	'<p class="data">'+ira._form.data.Zeitraum+'</p>';
//								}

			html +=			'</li>';

							var childAgeInfo = '';
							if(ira._form.data.Alter.length>0){
                                childAgeInfo = ' (';
								for(var i=0; i<ira._form.data.Alter.length; i++){
									if(ira._form.data.Alter[i]== null) {
                                        ira._form.data.Alter[i] = '<2';
									}
                                    childAgeInfo += ira._form.data.Alter[i];
									if(i<ira._form.data.Alter.length-1) {childAgeInfo += ', ';}
								}
                                childAgeInfo += ' Jahre)';
							}

			html +=			'<li class="clearfix">'+
								'<p class="field">Reisende</p><p class="data">'+
									ira._form.data.Erwachsene+(!ira._hasValue(ira._form.data.Kinder)?'':', '+ira._form.data.Kinder + childAgeInfo)+
								'</p>'+
							'</li>';

			html +=			'<li class="clearfix">'+
								'<p class="field">Anreise</p><p class="data">';
									if(ira._hasValue(ira._form.data.Anreise) == false && ira._hasValue(ira._form.data.Abflughafen) == false){
										html +=	'beliebig';
									}else{
										html += ira._getFormInfoForSummary(ira._form.data.Anreise, 'Anreise beliebig') +
										' / '+ira._getFormInfoForSummary(ira._form.data.Abflughafen, 'Ab: beliebig');
									}


			html +=				'</p>'+
							'</li>'+
							'<li class="clearfix">'+
								'<p class="field">Unterkunft</p><p class="data">';

									var Unterkunft = new Array();
									if(ira._hasValue(ira._form.data.Unterkunft)){
										Unterkunft.push(ira._form.data.Unterkunft);
									}
									if(ira._hasValue(ira._form.data.Zimmertyp)){
										Unterkunft.push(ira._form.data.Zimmertyp);
									}
									if(ira._hasValue(ira._form.data.Verpflegung)){
										Unterkunft.push(ira._form.data.Verpflegung);
									}
									if(Unterkunft.length<1){
										html += 'beliebig';
									}else{
										html += Unterkunft.join(' / ');
									}

//									if(ira._hasValue(ira._form.data.Unterkunft)==false 
//										&& ira._hasValue(ira._form.data.Zimmertyp) == false 
//										&& ira._hasValue(ira._form.data.Verpflegung==false) 
//									){
//										html += 'beliebig';
//									}else{
////										html += ira._getFormInfoForSummary(ira._form.data.Unterkunft,'beliebig')+
////												' / ' +ira._getFormInfoForSummary(ira._form.data.Zimmertyp,'Zimmer beliebig')+
////												' / '+ira._getFormInfoForSummary(ira._form.data.Verpflegung,'Verpflegung beliebig');								
//										html += ira._getFormInfoForSummary(ira._form.data.Unterkunft,'')+
//										' / ' +ira._getFormInfoForSummary(ira._form.data.Zimmertyp,'')+
//										' / '+ira._getFormInfoForSummary(ira._form.data.Verpflegung,'');								
//									}								
			html +=				'</p>'+
							'</li>'+
							'<li class="clearfix">'+
								'<p class="field">&nbsp;</p><div class="data">'+
									'<p id="iraStars">';
										switch(ira._form.data.Sterne){
										case  '2':
											html += '<span class="icon-star active" title="beliebig"></span>'+
													'<span class="icon-star active" title="2"></span>'+
													'<span class="icon-star " title="3"></span>'+
													'<span class="icon-star " title="4"></span>'+
													'<span class="icon-star " title="5"></span>'+
													'';
											break;
										case  '3':
											html += '<span class="icon-star active" title="beliebig"></span>'+
													'<span class="icon-star active" title="2"></span>'+
													'<span class="icon-star active" title="3"></span>'+
													'<span class="icon-star " title="4"></span>'+
													'<span class="icon-star " title="5"></span>'+
													'';
											break;
										case  '4':
											html += '<span class="icon-star active" title="beliebig"></span>'+
													'<span class="icon-star active" title="2"></span>'+
													'<span class="icon-star active" title="3"></span>'+
													'<span class="icon-star active" title="4"></span>'+
													'<span class="icon-star " title="5"></span>'+
													'';
											break;
										case  '5':
											html += '<span class="icon-star active" title="beliebig"></span>'+
													'<span class="icon-star active" title="2"></span>'+
													'<span class="icon-star active" title="3"></span>'+
													'<span class="icon-star active" title="4"></span>'+
													'<span class="icon-star active" title="5"></span>'+
													'';
											break;

										default:
											html += '<span class="icon-star active" title="beliebig"></span>'+
													'<span class="icon-star " title="2"></span>'+
													'<span class="icon-star " title="3"></span>'+
													'<span class="icon-star " title="4"></span>'+
													'<span class="icon-star " title="5"></span>'+
													'';

										}

			html+=				'</p>'+
							'</div>'+
							'</li>';
			if(ira._hasValue(ira._form.data.Reisedaten_Besondere_Wuensche)){
				html += 	'<li class="clearfix">'+
								'<div>'+
								'<p class="mb5"><b>Besondere W&uuml;nsche:</b><br />'+
									ira._nl2br(ira._form.data.Reisedaten_Besondere_Wuensche)+
								'</p></div>'+
							'</li>';
			}
			html+=			'<li class="clearfix">'+
								'<p class="field">Preis <span class="lower">p.</span>P.</p><p class="data">'+
									ira._form.data.Preis_von +',- € bis ' +ira._form.data.Preis_bis+',- € ' +
								'</p>'+
							'</li>'+
							editButton+
						'</ul>'+
					'</div>'+
				'</div>';
		return html;
	},
	_writeContactSummary: function(hideEditButton){
		var editButton = '';
		if(hideEditButton==undefined){
			editButton = '<li class="clearfix ar">'+
			'<a class="btngraysmall navright" href="javascript: ira._fromSummary(\'contactdata\')"><span>Bearbeiten</span></a>'+
			'</li>';

			var Content = new Array();
			if(ira._form.contact.contactChannel == 'email'){
				Content.push('Per E-Mail an:');
			}else{
				Content.push('Per R&uuml;ckruf an:');
			}
			//var fullname = new Array();
			//if(ira._hasValue(ira._form.contact.Salutation)){fullname.push(ira._form.contact.Salutation);}
			//if(ira._hasValue(ira._form.contact.iraVorname)){fullname.push(ira._form.contact.iraVorname);}
			//if(ira._hasValue(ira._form.contact.iraNachname)){fullname.push(ira._form.contact.iraNachname);}
			//Content.push(fullname.join(' '));
			Content.push(ira._form.contact.iraEmail);
			if(ira._hasValue(ira._form.contact.iraPhoneno)){
				Content.push('Tel. ' + ira._form.contact.iraPhoneno);
			}
			if(ira._hasValue(ira._form.contact.iraCbTime)){
				Content.push(ira._form.contact.iraCbTime);
			}

			var html = '<div class="sumElm clearfix" id="summaryContact">'+
							'<div class="icon">'+
							'<span id="iraIconcontact"></span>'+
						'</div>'+
						'<div class="sumElmContent clearfix">'+
							'<ul>'+
								'<li class="clearfix">'+
									'<p class="sumHl">So m&ouml;chte ich meine individuellen Angebote erhalten:</p>'+
								'</li>'+
								'<li class="clearfix">'+
									'<p>'+Content.join('<br />')+'</p>'+
								'</li>'+
								editButton+
							'</ul>'+
						'</div>'+
					'</div>';
			return html;

		}else{
			return '';
		}
	},
	_writeCompleteSummary : function(hideEditButton){
		ira._form.summaryset=true;
		return 	ira._writeThemeSummary(hideEditButton) +
				ira._writeDestSummary(hideEditButton) +
				ira._writeDataSummary(hideEditButton) +
				ira._writeContactSummary(hideEditButton) +
				''
			;
	},
	_setCaptcha:function(url){
		url+='/public/captcha/captcha.php';
		var getParam = new Array();
		if(ira._sid!=null){
			getParam.push('SID='+ira._sid);
		}
		getParam.push('n=ira');
		var d = new Date();
		getParam.push('t='+d.getTime());
		url+='?'+getParam.join('&');
		$('#iraCaptchaImg').attr('src',url);
	},
	_setContactCaptcha:function(id){
		var url = ira._vars.mainurl+'/public/captcha/captcha.php';
		var getParam = new Array();
		if(ira._sid!=null){
			getParam.push('SID='+ira._sid);
		}
		getParam.push('n='+id);
		var d = new Date();
		getParam.push('t='+d.getTime());
		url+='?'+getParam.join('&');
		$('#iraLayer_captcha').attr('src',url);
	},
	_getEtPagename:function(){
		var myurl = $.extend({}, {url:window.location.href});
		myurl = myurl.url.split('?');
		return myurl[0];
	},
	_nl2br: function (str, is_xhtml) {
		var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>';
		return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
	},
	_setthemetags:function(tags) {
		if(tags.length > 1) {
			ira._vars.themetags = tags;
			if(ira._form.theme.Reisethema.length > 0) {
				for(var x=0;x<ira._form.theme.Reisethema.length;x++){
					if(($.inArray(ira._form.theme.Reisethema[x],ira._vars.themetags)==-1) && ira._form.theme.Reisethema[x]!="") {
						ira._vars.themetags.push(ira._form.theme.Reisethema[x]);
					}
				}
			}
		} else {
			ira._vars.themetags = ira._form.theme.Reisethema;
		}
	},
	_setdesttags:function(tags) {
		if(tags.length > 1) {
			ira._vars.desttags = tags;
			if(ira._form.dest.Reiseziel.length > 0) {
				for(var x=0;x<ira._form.dest.Reiseziel.length;x++){
					if(($.inArray(ira._form.dest.Reiseziel[x],ira._vars.desttags)==-1) && ira._form.dest.Reiseziel[x]!="") {
						ira._vars.desttags.push(ira._form.dest.Reiseziel[x]);
					}
				}
			}
		} else {
			ira._vars.desttags = ira._form.dest.Reiseziel;
		}
	}
};
