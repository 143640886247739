export default {
	debug : false,
	breakpoints:[359, 479, 767, 979],
	breakpointMobile:767,
	handleMultibreaks : false,
	nextBreakpoint : null,
	keepBreakpoint : null,
	status:'d',
	funcd:[],
	funcm:[],
	funcd_once:[],
	funcm_once:[],
	objimg:[],
	onceEventFiredDesktop 	: false,
	eventFiredDesktop 		: false,
	onceEventFiredMobile 	: false,
	eventFiredMobile 		: false,
	isFirstEvent	 		: true,
	lastBreakpoint	 		: 0,
	resizeDirection			: null,
	regd:function(e){mobileAction.funcd.push(e);},
	regm:function(e){mobileAction.funcm.push(e);},
	regd_once:function(e){mobileAction.funcd_once.push(e);},
	regm_once:function(e){mobileAction.funcm_once.push(e);},
	resize:function(){this.action();},
	init:function(){
		this.win=$(window)
		var self = this;
				
		this.breakpoints = this.iterator(this.breakpoints);
		
/*indicator-function for all events declared here*/
		var doc = $(document);	
		var maEvents = [
//		    'resizedesktop',
//		    'resizemobile',
		    'breakpoint',
		    'moddesktop',
		    'modmobile',
		    'moddesktopsmart',
		    'modmobilesmart',
		    'f',
		    'modmobileonce',
		    'loaddesktop',
		    'loadmobile'
		];
		doc.on(maEvents.join(' '), function(e){
			if(self.debug === true){
				var appendix = '';
				if(e.status){
					appendix += ' - status: ' + e.status;
				}
				if(e.breakpoint){
					appendix += ' - breakpoint: ' + e.breakpoint;
				}
				if(typeof(e.isfirst) == 'boolean'){
					appendix += ' - isFirstEvent: ' + e.isfirst;
				}
				debug(e.type + appendix);
			} 			
		});	
		
		self.getDeviceInfo();
		
		if(this.handleMultibreaks===true){
			this.handleBreakPoints();			
		}
		
		window.orientMode = null;
		if(self.win.height() > self.win.width()){
			window.orientMode = 'portrait';
		}else{
			window.orientMode = 'landscape';
		}
		doc.on('breakpoint', function(){			
			self.changeViewport();
		});
		window.onorientationchange = function(){
			self.changeViewport();
		}
		this.action();

		window.onresize = function() {
			self.getDeviceInfo();			
			mobileAction.resize();		
			window.clearTimeout(self.TIMER);
			self.TIMER = window.setTimeout(function(){
				self.smartAction();
			}, 100);			
		};
	},
	changeViewport:function(){
		var self = this;
		var vpObj = $('#mtvp');		
		window.setTimeout(function(){
			self.getDeviceInfo();
			if(!window.deviceInfo.viewportMobile){
				//in desktopansicht den viewport modden, wenn portraitmodus aktiv ist
				if($('body').hasClass('ios')){
					vpwidth = '1050';
					vpObj.attr('content', 'width='+vpwidth+',maximum-scale=1.0');				
				}
//				var vpwidth = '980';							
//				if('portrait' == window.orientMode){	
//					if($('body').hasClass('ios')){
//						var vpwidth = '990';	
//					}	
//					vpObj.attr('content', 'width='+vpwidth+'');					
//				}else{
//					if($('body').hasClass('ios')){
//						vpwidth = '1050';
//						vpObj.attr('content', 'width='+vpwidth);				
//					}else{
//						vpObj.attr('content', 'width=device-width');
//					}
//				}
				
			}else{
				vpObj.attr('content', 'width=device-width');
			}
		}, 50);
		
	},
	action:function(){
		var self = this;
		if(this.handleMultibreaks===true){
			this.getNextBreakpoint(this.breakpoints.compare(this.win.width()), this.win.width());			
		}
		if(this.win.width()>mobileAction.breakpointMobile){
			self.status='d';
			if(!this.onceEventFiredDesktop){
				$.event.trigger({
					type : 'moddesktoponce'
				});
				this.onceEventFiredDesktop = true;
			}
			if(!this.eventFiredDesktop){
				$.event.trigger({
					type : 'moddesktop',
					isfirst : self.isFirstEvent
				});
				$.event.trigger({
					type : 'breakpoint',
					status : self.status,
					isfirst : self.isFirstEvent
				});
				this.eventFiredDesktop = true;
				this.eventFiredMobile = false;
				if(self.isFirstEvent){
					self.isFirstEvent = false;
				}
			}
			this.actionD();
		}else{
			self.status='m';
			if(!this.onceEventFiredMobile){
				$.event.trigger({
					type : 'modmobileonce'
				});
				this.onceEventFiredMobile = true;
			}
			if(!this.eventFiredMobile){
				$.event.trigger({
					type : 'modmobile',
					isfirst : self.isFirstEvent
				});
				$.event.trigger({
					type : 'breakpoint',
					status : self.status,
					isfirst : self.isFirstEvent
				});
				this.eventFiredMobile = true;
				this.eventFiredDesktop = false;
				if(self.isFirstEvent){
					self.isFirstEvent = false;
				}
			}
			this.actionM();
		}
	},
	getNextBreakpoint : function(comparison, width){
		var self = this;

		if(self.resizeDirection == 'slim'){
			if(comparison.prev != null){
				self.nextBreakpoint = comparison.prev;
			}					
		}else if(self.resizeDirection == 'wide'){
			if(comparison.next != null){
				self.nextBreakpoint = comparison.next;	
			}					
		}
		
		if(self.nextBreakpoint != self.keepBreakpoint){
			self.keepBreakpoint = self.nextBreakpoint;
			self.status='m';
			if(self.nextBreakpoint >= mobileAction.breakpointMobile){
				self.status='d';
			}
			$.event.trigger({
				type : 'breakpoint',
				status : self.status,
				breakpoint : self.nextBreakpoint,
				isfirst : self.isFirstEvent
			});
			if(self.isFirstEvent){
				self.isFirstEvent = false;
			}
		}
	},
	iterator : function(arr){
		var cur = 0;
	    arr.next = (function () { return (++cur >= this.length) ? undefined : this[cur]; });
	    arr.prev = (function () { return (--cur < 0) ? undefined : this[cur]; });
	    arr.getCur = (function () { return cur; });
	    arr.setCur = (function (newCur) { cur = newCur; return cur;});
	    arr.compare =(function(value){
	    	var i=null;
	    	var next =null;
	    	var prev = null;
	    	for(i=0; i<arr.length; i++){
	    		arr.setCur(i);
	    		if(arr[i] >= value){
	    			prev = arr.prev();
	    			next = arr[i];
	    			if(arr[i] == value){
	    				i++;
	    				arr.setCur(i);
	    				next = arr[i];
	    			}
	    			break;
	    		}
	    	}
	    	//für das "durchschleifen" bei undefined
	    	//ist next oder prev undefined wird automatisch der erste oderletzte wert des arrays eingetragen
	    	//kein durchschelifen? null wert rein und den anderen shizzle raus
	    	if(next == undefined){
	    		//next = arr[0];
	    		next = null;
	    	}
	    	if(prev == undefined){
	    		//prev = arr[arr.length-1];
	    		prev = null;
	    	}
	    	return {
	    		prev : prev,
	    		next : next
	    	};
	    });
	    return arr;
	},
	handleBreakPoints :function(){
		var lastWinWidth = 0;
		var self = this;
		self.win.resize(function(){
			detectDirection();
		});
		
				
		function detectDirection() {
	        var ww = self.win.width();
	        
	        if (ww > lastWinWidth) {	      
	            direction = 'wide';
	        } else if (ww < lastWinWidth ){
	            direction = 'slim';
	        } else {
	            // resize of height (not neccessary here)
	            direction = 0;
	        }
	        lastWinWidth = ww;

	       // set the direction
	       self.resizeDirection = direction;  
	    }
		
	},
	actionD:function(){
		for(var x=0;x<this.funcd_once.length;x++){this.funcd_once[x]();}
		for(var x=0;x<this.funcd.length;x++){this.funcd[x]();}
		this.status='d';
		this.funcd_once=new Array();
		$.event.trigger({
			type : 'resizedesktop'
		});
	},
	actionM:function(){
		for(var x=0;x<this.funcm_once.length;x++){this.funcm_once[x]();}
		for(var x=0;x<this.funcm.length;x++){this.funcm[x]();}
		this.status='m';
		this.funcm_once=new Array();
		$.event.trigger({
			type : 'resizemobile'
		});
	},
	preloadImage:function(src){
		if(this.objimg[src]==undefined){
			this.objimg[src]=new Image();
			this.objimg[src].src=src;
		}
	},
	smartAction:function(){	
		var self = this;
		if(self.win.width()>self.breakpointMobile){			
			self.status='d';
			$.event.trigger({
				type : 'moddesktopsmart'
			});
		}else{
			self.status='m';
			$.event.trigger({
				type : 'modmobilesmart'
			});
		}
	},
	getDeviceInfo : function(){
		var self = this;
		if(self.win.width() > self.breakpointMobile){
			window.deviceInfo = {
				viewportMobile : false,
				istouchdevice : (typeof(window.ontouchstart)==='undefined' ? false:true)
			};
		}else{
			window.deviceInfo = {
				viewportMobile : true,
				istouchdevice : (typeof(window.ontouchstart)==='undefined' ? false:true)
			};
		}
		if(self.win.height() > self.win.width()){
			window.deviceInfo['orientMode'] = 'portrait';
			window['orientMode'] = 'portrait';
		}else{
			window.deviceInfo['orientMode'] = 'landscape';
			window['orientMode'] = 'landscape';
		}
	},
};


