<template>
  <footer id="footer">
    <section class="linkcollection">
      <div class="inner">
        <div class="element" :class="{open:linkCollection==='topDestinations'}">
          <p class="label" @click="toggleLinkCollection('topDestinations')"><span class="iLabWrap">Top Reise Ziele</span></p>
          <ul class="link_collection_1">
            <li><a :href="$root.linkHref('/reise-ideen-beratung/europa/portugal')"><span>Portugal</span></a></li>
            <li><a :href="$root.linkHref('/reise-ideen-beratung/afrika/suedafrika')"><span>Südafrika</span></a></li>
            <li><a
                :href="$root.linkHref('/reise-ideen-beratung/asien/vereinigte-arabische-emirate/dubai')"><span>Dubai</span></a>
            </li>
            <li><a
                :href="$root.linkHref('/reise-ideen-beratung/amerika/nordamerika/usa/florida')"><span>Florida</span></a>
            </li>
            <li><a :href="$root.linkHref('/reise-ideen-beratung/europa/spanien/balearen/mallorca')"><span>Mallorca</span></a>
            </li>
          </ul>
          <a class="limore" :href="$root.linkHref('/topreiseziele/a-e')">
            <span><span class="icon-pfeil-rechts"></span>Alle Reiseziele</span>
          </a>
        </div>
        <div class="element" :class="{open:linkCollection==='topThemes'}">
          <p class="label" @click="toggleLinkCollection('topThemes')"><span class="iLabWrap">Top Reise Themen</span></p>
          <ul class="link_collection_1">
            <li><a :href="$root.linkHref('/reise-ideen-beratung/familienurlaub')"><span>Familienurlaub</span></a></li>
            <li><a :href="$root.linkHref('/reise-ideen-beratung/rundreisen')"><span>Rundreisen</span></a></li>
            <li><a :href="$root.linkHref('/reise-ideen-beratung/staedtereisen')"><span>Städtereisen</span></a></li>
            <li><a :href="$root.linkHref('/reise-ideen-beratung/kreuzfahrten')"><span>Kreuzfahrten</span></a></li>
            <li><a :href="$root.linkHref('/reise-ideen-beratung/fernreisen')"><span>Fernreisen</span></a></li>
          </ul>
          <a class="limore" :href="$root.linkHref('/topreisethemen')">
            <span><span class="icon-pfeil-rechts"></span>Alle Reisethemen</span>
          </a>
        </div>
        <div class="element m0" :class="{open:linkCollection==='topRecommendations'}">
          <p class="label" @click="toggleLinkCollection('topRecommendations')"><span class="iLabWrap">Top Empfehlungen</span></p>
          <ul class="link_collection_1">
            <li><a :href="$root.linkHref('/reise-ideen-beratung/europa/spanien/andalusien-rundreisen')"><span>Andalusien Rundreisen</span></a>
            </li>
            <li><a :href="$root.linkHref('/reise-ideen-beratung/europa/frankreich/paris-kurzurlaub')"><span>Paris Kurzurlaub</span></a>
            </li>
            <li><a :href="$root.linkHref('/reise-ideen-beratung/asien/tuerkei-familienurlaub')"><span>Türkei Familienurlaub</span></a>
            </li>
            <li><a :href="$root.linkHref('/reise-ideen-beratung/europa/norwegen-kreuzfahrten')"><span>Norwegen Kreuzfahrten</span></a>
            </li>
            <li><a
                :href="$root.linkHref('/reise-ideen-beratung/afrika/suedafrika-rundreisen')"><span>Südafrika Rundreisen</span></a>
            </li>
          </ul>
          <a class="limore" :href="$root.linkHref('/topempfehlungen')">
            <span><span class="icon-pfeil-rechts"></span>Alle Empfehlungen</span>
          </a>
        </div>
        <div class="element last" :class="{open:linkCollection==='travelTipps'}">
          <p class="label" @click="toggleLinkCollection('travelTipps')"><span class="iLabWrap">Reisetipps von A-Z</span></p>
          <ul class="link_collection_1">
            <li><a :href="$root.linkHref('/reisetipps/a-e')"><span>Reisetipps A-E</span></a></li>
            <li><a :href="$root.linkHref('/reisetipps/f-j')"><span>Reisetipps F-J</span></a></li>
            <li><a :href="$root.linkHref('/reisetipps/k-o')"><span>Reisetipps K-O</span></a></li>
            <li><a :href="$root.linkHref('/reisetipps/p-t')"><span>Reisetipps P-T</span></a></li>
            <li><a :href="$root.linkHref('/reisetipps/u-z')"><span>Reisetipps U-Z</span></a></li>
          </ul>
        </div>
        <div class="clear"></div>
      </div>
    </section>

    <section class="teaserband">
      <span class="dtxtinline">Beratung von Reise-Experten + Persönlicher Ansprechpartner = Mehr Urlaub fürs Geld</span>
      <span class="mtxtinline">Beratung von Reise-Experten + <br />Persönlicher Ansprechpartner = <br />Mehr Urlaub fürs Geld</span>
    </section>

    <section class="footer">
      <div class="inner">
        <div class="element iconlist dtxtblock">
          <ul>
            <li>
              <a class="wunschreise pointer" onclick='ira._open({mid:1000,channel:"allgemein"});'>Wunschreise anfragen</a>
            </li>
            <li>
              <a class="reisenews" :href="$root.linkHref('/newsletter/newsletter_anmeldung')">Newsletter</a>
            </li>
            <li>
              <a class="buchen" :href="$root.linkHref('/reise-angebote')">Online buchen</a>
            </li>
          </ul>
        </div>
        <div class="element contact">
          <p class="dtxtblock"><a class="label" onclick='javascript:ira._openContact({mid:1000,channel:"allgemein"})'>Kontakt</a></p>
          <p class="dtxtblock"><a :href="$root.linkHref('/reise-experten-vor-ort')">DERPART in Ihrer Nähe</a></p>
          <p class="dtxtblock"><a onclick='javascript:ira._openContact({mid:1000,channel:"allgemein"})'>Nachricht schreiben</a></p>
          <p class="textinfo">Service-Hotline*</p>
          <p class="phone"><span class="clickPhoneMob" data-href="tel:+49 6126 953929">0 61 26 / 95 39 29</span></p>
        </div>
        <div class="element m0 dtxtblock" id="footerservice">
          <div v-if="nav.service">
            <p><a class="label" :href="$root.linkHref(nav.service)">{{ nav.service.l }}</a></p>
            <ul class="service_1" v-if="nav.service.c">
              <li v-for="item in nav.service.c.slice(0,4)" class="service_1_li">
                <a
                    class="service_1_link"
                    :href="$root.linkHref(item)"
                    :target="$root.linkTarget(item)"
                >{{ item.l }}</a>
              </li>
            </ul>
            <a v-if="nav.service.c.length>4" class="more" :href="$root.linkHref(nav.service)"><span></span>Mehr</a>
          </div>
        </div>
        <div class="element last dtxtblock" id="footerabout">
          <div v-if="nav.about">
            <p><a class="label" :href="$root.linkHref(nav.about)">{{ nav.about.l }}</a></p>
            <ul class="service_1" v-if="nav.about.c">
              <li v-for="item in nav.about.c.slice(0,4)" class="service_1_li">
                <a
                  class="service_1_link"
                  :href="$root.linkHref(item)"
                  :target="$root.linkTarget(item)"
                  >{{ item.l }}</a>
              </li>
            </ul>
            <a v-if="nav.about.c.length>4" class="more" :href="$root.linkHref(nav.about)"><span></span>Mehr</a>
          </div>
        </div>
        <div id="certWrap">
          <a id="logodrv" href="https://v-i-r.de/ueber-uns/was-wir-tun/code-of-conduct-onlinemarketing/" target="_blank"><img src="../images/logo_drv.png" alt="DRV" /></a>
        </div>
        <div class="clear"></div>
      </div>
    </section>

    <section class="socialmedia">
      <div class="inner">
        <p id="socialclaim">DERPART ist immer eine Empfehlung wert.</p>
        <div id="sociallink">
          <div class="left mr">
            Bleiben Sie mit uns in Verbindung:
          </div>
          <a target="_blank" id="footer_facebook" href="https://www.facebook.com/DERPART"></a>
          <a target="_blank" id="footer_instagram" href="https://www.instagram.com/derpart.reise/"></a>
          <a target="_blank" id="footer_linkedin" href="https://www.linkedin.com/company/derpart-reisevertrieb-gmbh/"></a>
          <a target="_blank" id="footer_xing" href="https://www.xing.com/pages/derpartreisevertriebgmbh"></a>
        </div>
        <div class="appdownload">
          <p>meinDERPART App herunterladen:</p>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=meinderpart.com">
            <img src="../images/google-play-badge.png" />
          </a>
          <a target="_blank" href="https://itunes.apple.com/de/app/meinderpart/id1297269382?mt=8">
            <img src="../images/app_store-badge.svg" />
          </a>
        </div>
      </div>
    </section>

    <section class="imprint">
      <div class="inner company">
        &copy; DERPART Reisevertrieb GmbH<span class="web">Geschäftsreisen:
        <a :href="$root.dtsHref" target="_blank">{{ $root.dtsDomain }}</a></span>
      </div>
      <div class="inner nav_imprint">
        <span v-for="item in nav.imprint">
          <a
              class="nav_imprint_1_link"
              :href="$root.linkHref(item)"
              :target="$root.linkTarget(item)"
          ><span>{{ item.l }}</span></a><wbr>
        </span>
      </div>
      <div class="inner">
        <span class="provider mb">* Es gelten die Verbindungspreise Ihres Telefonanbieters.</span>
        <span class="provider mb">Sie haben Fragen zu Ihrer Buchung im Reisebüro? <a :href="$root.linkHref('/reise-experten-vor-ort')">Hier</a> finden Sie Ihren Ansprechpartner vor Ort.</span>
        <span class="provider mb">Nach geltendem Recht sind wir verpflichtet, unsere Kunden auf die Existenz der europäischen <a href="http://ec.europa.eu/odr" target="_blank">Online-Streitbeilegungs-Plattform</a> hinzuweisen:<br /><a href="http://ec.europa.eu/odr" target="_blank">http://ec.europa.eu/odr</a>.</span>
        <span class="provider">Die DERPART Reisevertrieb GmbH, alle angeschlossenen DERPART Gesellschafter und Franchisenehmer nehmen nicht an Streitbeilegungsverfahren einer Verbraucherschlichtungsstelle teil und sind dazu auch nicht verpflichtet.</span>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "central-footer",
  data(){
    return {
      linkCollection:null,
    }
  },
  props:{
    tenant:{
      type: Object,
      required: true,
    },
    nav:{
      type:Object,
      required: true,
    },
  },
  methods:{
    toggleLinkCollection(id){
      this.linkCollection=this.linkCollection===id?null:id;
    }
  }
}
</script>

<style scoped>

</style>