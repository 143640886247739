<template>
  <footer id="footer">

    <section class="teaserband">
      <span class="dtxtinline">Beratung von Reise-Experten + Persönlicher Ansprechpartner = Mehr Urlaub fürs Geld</span>
      <span class="mtxtinline">Beratung von Reise-Experten + <br/>Persönlicher Ansprechpartner = <br/>Mehr Urlaub fürs Geld</span>
    </section>

    <section class="footer">
      <div class="inner mand">
        <div class="element iconlist dtxtblock">
          <ul>
            <li v-for="item in nav.footer">
              <a :class="item.cL" :href="$root.linkHref(item)" :target="$root.linkTarget(item)">{{ item.l }}</a>
            </li>
          </ul>
        </div>
        <div class="element contact">
          <p class="label">{{ tenant.name }}</p>
          <p class="info">
            {{ tenant.address }}<br/>
            {{ tenant.zip }} {{ tenant.city }}
          </p>
          <dl class="info" v-if="$root.isPhone||$root.isFax">
            <dt v-if="$root.isPhone">Tel.</dt>
            <dd v-if="$root.isPhone"><span :href="$root.phoneHref">{{ $root.phoneLabel }}</span></dd>
            <dt v-if="$root.isFax">Fax.</dt>
            <dd v-if="$root.isFax"><span :href="$root.faxHref">{{ $root.faxLabel }}</span></dd>
          </dl>
        </div>
        <div class="element" id="footerservice">
          <p class="label">Öffnungszeiten</p>
          <div id="businesshours">
            <dl v-for="item in tenant.businessHours">
              <dt>{{ businessHoursDay(item) }}</dt>
              <dd v-html="businessHoursTime(item)"></dd>
              <div class="clear"></div>
            </dl>
            <div class="clear"></div>
            <div class="availInfo" v-if="tenant.availabilitySrc&&tenant.availabilityText">
              <p>
                <a :href="$root.linkHref(tenant.availabilitySrc)" data-btattached="true">
                  {{ tenant.availabilityText }}
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="element last dtxtblock" id="footerabout">
          <p class="label">Kontakt</p>
          <p><a class="pointer" :onclick="$root.contactLink()">Nachricht
            schreiben</a></p>
          <p><a class="pointer" :onclick="$root.callbackLink()">Rückruf-Service</a>
          </p>
          <p><a class="pointer" :onclick="$root.iraLink()">Wunschreise anfragen</a></p>
        </div>
        <div id="certWrap">
          <a id="logodrv" href="https://v-i-r.de/ueber-uns/was-wir-tun/code-of-conduct-onlinemarketing/"
             target="_blank"><img src="../images/logo_drv.png" alt="DRV"/></a>
        </div>
        <div class="clear"></div>
      </div>
    </section>

    <section class="socialmedia">
      <div class="inner">
        <div v-if="isSocialMedia">
          <p id="socialclaim">DERPART ist immer eine Empfehlung wert.</p>
          <div id="sociallink">
            <div class="left mr">
              Bleiben Sie mit uns in Verbindung:
            </div>
            <a v-if="tenant.facebook" target="_blank" id="footer_facebook" title="Facebook" :href="tenant.facebook"></a>
            <a v-if="tenant.instagram" target="_blank" id="footer_instagram" title="Instagram" :href="tenant.instagram"></a>
            <a v-if="tenant.twitter" target="_blank" id="footer_twitter" title="Twitter" :href="tenant.twitter"></a>
            <a v-if="tenant.yelp" target="_blank" id="footer_yelp" title="Yelp" :href="tenant.yelp"></a>
            <a v-if="tenant.xing" target="_blank" id="footer_xing" title="Xing" :href="tenant.xing"></a>
          </div>
        </div>
        <div v-if="tenant.showAppFooter" class="appdownload">
          <p>meinDERPART App herunterladen:</p>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=meinderpart.com">
            <img src="../images/google-play-badge.png"/>
          </a>
          <a target="_blank" href="https://itunes.apple.com/de/app/meinderpart/id1297269382?mt=8">
            <img src="../images/app_store-badge.svg"/>
          </a>
        </div>
      </div>
    </section>

    <section class="imprint">
      <div class="inner company">
        &copy; DERPART Reisevertrieb GmbH <span class="web" v-if="tenant.showDtsFooter">Geschäftsreisen:
        <a :href="$root.dtsHref" target="_blank">{{ $root.dtsDomain }}</a></span>
      </div>
      <div class="inner nav_imprint">
        <span v-for="item in nav.imprint">
          <a
              class="nav_imprint_1_link"
              :href="$root.linkHref(item)"
              :target="$root.linkTarget(item)"
          ><span>{{ item.l }}</span></a><wbr>
        </span>
      </div>
      <div class="inner">
        <span class="provider mb">
          Nach geltendem Recht sind wir verpflichtet, unsere Kunden auf die Existenz der europäischen
          <a href="http://ec.europa.eu/odr" target="_blank">Online-Streitbeilegungs-Plattform</a> hinzuweisen:<br/>
          <a href="http://ec.europa.eu/odr" target="_blank">http://ec.europa.eu/odr</a>.
        </span>
        <span class="provider">
          Die DERPART Reisevertrieb GmbH, alle angeschlossenen DERPART Gesellschafter und Franchisenehmer nehmen nicht
          an Streitbeilegungsverfahren einer Verbraucherschlichtungsstelle teil und sind dazu auch nicht verpflichtet.
        </span>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "partner-footer",
  props: {
    tenant: {
      type: Object,
      required: true,
    },
    nav: {
      type: Object,
      required: true,
    },
  },
  methods:{
    businessHoursDay(item){
      let r=null
      switch (item.d){
        case 1:r='Montag';break;
        case 2:r='Dienstag';break;
        case 3:r='Mittwoch';break;
        case 4:r='Donnerstag';break;
        case 5:r='Freitag';break;
        case 6:r='Samstag';break;
        case 7:r='Sonntag';break;
      }
      return r
    },
    businessHoursTime(item){
      if (item.t){
        if (
            item.t[2] !== "" &&
            item.t[3] !== ""
        ) {
          return item.t[0] + "-" + item.t[2] + " Uhr<br>" + item.t[3] + "-" + item.t[1] + " Uhr";
        } else {
          return item.t[0] + "-" + item.t[1] + " Uhr";
        }
      }
      return 'geschlossen'
    },
  },
  computed:{
    isSocialMedia(){
      return !!(
          this.tenant.facebook ||
          this.tenant.instagram ||
          this.tenant.twitter ||
          this.tenant.yelp||
          this.tenant.xing
      );
    }
  }
}
</script>

<style scoped>

</style>