$.widget( "custom.starselect", {

	options: {
		icontag:		'<span class="icon-star">',
		name:			null,
		stars:			{},
		value:			null,
		change:			function(){}
	},
	_create: function() {
		var self = this;
		var starslength = this.options.stars.length;
		this.label = $('<span class="starlabel" style="display: none;"></span>');
		this.input = $('<input type="hidden">');
		this.input.attr('name',this.options.name);
		this.element.append(this.input);
		var setactive = true;
		for(var x=0;x<this.options.stars.length;x++){
			var icon=$(this.options.icontag);
			var title = this.options.stars[x].title;
			if(title!=undefined){icon.attr('title',title);}
			var value=this.options.stars[x].value;
			if(value==undefined){value=x;}
			if(this.options.value==null){
				this.options.value=value;
			}
			if(setactive){
				icon.addClass('active');
				this.input.attr('value',value);
				if(title!=undefined){this.label.html(title);}
				if(value==this.options.value){
					setactive = false;
				}
			}
			icon.data('value',value);
			this._on(icon,{click:'_select'})
			this._on(icon,{mouseover:'_mover'})
			this._on(icon,{mouseout:'_mout'})
			this.element.append(icon);
		}
		this.icon = this.element.find('span');
		//this.element.append(this.label);
	},
	_select: function(event){
		var self = this;
		var obj = $(event.currentTarget);
		this.options.value = obj.data('value');
		this.input.attr('value',this.options.value);
		this.icon.removeClass('active');
		var setclass = true;
		this.icon.each(function(){
			var i = $(this);
			if(setclass){i.addClass('active');}
			if(i.data('value')==self.options.value){
				self.label.html(i.attr('title'));
				self.options.change();
				setclass=false;
			}
		});
	},
	_mover: function(event){
		var self=this;
		var obj = $(event.currentTarget);
		var value = obj.data('value');
		this.icon.removeClass('active');
		var setclass = true;
		this.icon.each(function(){
			var i = $(this);
			if(setclass){
				i.addClass('active');
			}
			if(i.data('value')==value){
				setclass=false;
				self.label.html(i.attr('title'));
			}
		});
	},
	_mout: function(event){
		var self=this;
		this.icon.removeClass('active');
		var setclass = true;
		this.icon.each(function(){
			var i = $(this);
			if(setclass){
				i.addClass('active');
			}
			if(i.data('value')==self.options.value){
				setclass=false;
				self.label.html(i.attr('title'));
			}
		});
	},
	setValue:function(value){
		var self=this;
		self.options.value=value;
		this.input.attr('value',this.options.value);
		this.icon.removeClass('active');
		var setclass = true;
		this.icon.each(function(){
			var i = $(this);
			if(setclass){i.addClass('active')}
			if(i.data('value')==self.options.value){
				self.label.html(i.attr('title'));
				setclass=false;
			}
		});
		return this;
	},
	getValue:function(){
		return this.options.value;
	}
});