/*
 * 
boundaries
wrapper
 * */
if(window.jQuery)(function($){
var defaults = {
	ajaxUrl 				:	'query/suggestedSearchDummy.php',
	ajaxRequest 			:	'get',
	ajaxWait				:	200,
	ajaxSuccessCallBack 	:	function(){$.noop();},
	ajaxErrorCallBack 		:	function(){$.noop();},
	selectCallBack 			:	function(){$.noop();},
	openingCallBack			:	function(){$.noop();},
	submitCallBack 			:	function(){$.noop();},
	minInput				:	1,
	openDelay				:	200,
	slideTime				:	50,
	openTimeout				:	TimeSettings['ResWrapOpen'],
	closeTimeout			:	TimeSettings['ResWrapClose'],
	submitTimeout			:	TimeSettings['SsSubmit'],
	tabletTime				:	1000,
	requestTimeout			:	TimeSettings['ssAjRequest'],
	slideToggleTime			:	300,
	keySlideBlock			:	1,
	isTablet				:   false
};
//Plugin-Globale Variablen
var reg = [];
var openSearch = [];

//erzeugt ein HTML-Element mit id und klasse und hängt dieses als letztes Element in den als "hook" definierten htmlbereich
function createElement(id, tag, className, hook, addAttributes){
	var objCreated = document.createElement(tag);
	if(id!= undefined && id != null && id !=''){
		objCreated['id']=id;
	}
	if(className!= undefined && className != null && className !=''){
		objCreated['className'] = className; 
	}
	if(addAttributes != undefined && addAttributes != null){
		for(e in addAttributes){
			objCreated[e] = addAttributes[e];
		}
	}
	hook.appendChild(objCreated);
	return objCreated;
}

function openSuggestWindow(aktObj, toggleTime, o){

	for(var i=0; i<openSearch.length; i++){
		if(aktObj != openSearch[i]){
			closeSuggestWindow(openSearch[i], 0);
			openSearch[i].val('');
		}
	}
	//alle offenen objekte werden geschlossen und dann wird das Array neu und leer definiert
	openSearch = [];
	openSearch.push(aktObj);
	aktObj.data('suggestWrapJQ').slideDown(toggleTime, function(){
		aktObj.data('suggestWrapJQ').css('display', 'block');
		
		var allItems = aktObj.data('suggestWrapJQ').find('ul li');
		var itemProps = [];
		var i=0; 
		do{
			var lastHeight = 0;
			var aktLiObj = $(allItems[i]);
			if(i>0){
				lastHeight= itemProps[i-1].sum;	
			}
			itemProps.push({
				sum : aktLiObj.outerHeight(true)+lastHeight,
				height : aktLiObj.outerHeight(true)
				
			});
			i++;
		}while(i<allItems.length);
		aktObj.data('itemProps', itemProps);
		aktObj.data('suggestWrapJQ').get(0).scrollTop = 0;
		$(document).one('click', function(e){
			docClick(aktObj, e);
		});
		if($.isFunction(o.openingCallBack)){
			o.openingCallBack();
		}
	});
}

function docClick(aktObj, e){
	if(e.target != aktObj.get(0)){
		if(e.which == 1){
			closeSuggestWindow(aktObj, 0);
		}else{
			$(document).one('click', function(e){
				docClick(aktObj, e);
			});
		}
	}else{
		$(document).one('click', function(e){
			docClick(aktObj, e);
		});
	}
}

function closeSuggestWindow(aktObj, toggleTime){
	if($.inArray(aktObj, openSearch) != -1){
		// Check if we have an data slot
		var data = aktObj.data('suggestWrapJQ');
		if(typeof data.slideUp !== 'undefined'){
			data.slideUp(toggleTime, function(){
				aktObj.data('suggestWrapJQ').css('display', 'none').get(0).innerHTML = '';
			});
		}
	}
}

function prepareSuggestHTML(aktObj, jsonObj, o){
	var suggestWrap = document.getElementById(aktObj.data('suggestWrap'));
	var ul_id = aktObj.data('suggestListId');
	var suggestItems = '<ul id="'+ul_id+'" class="suggestList">';
	
	for(e in jsonObj){
		if(jsonObj[e].ts == aktObj.data('sendTime')){
			var innerHtml = '<div id="buero_'+jsonObj[e].id+'" data-office="'+jsonObj[e].id+'" data-contactemail="'+jsonObj[e].email +'" data-address="'+jsonObj[e].address+'" data-name="'+jsonObj[e].text+'" data-zip="'+jsonObj[e].zip+'" data-city="'+jsonObj[e].city+'">'+
								'<p class="name">'+
									jsonObj[e].text+
								'</p>'+
								'<p class="address">'+
									jsonObj[e].address+
								'</p>'+
								'<p class="zipcity">'+
									jsonObj[e].zip+' ' +jsonObj[e].city+
								'</p>'+							
							'</div>';
			suggestItems += '<li class="suggestRes"><a href="javascript:void(0);" rel="'+jsonObj[e].text+'">'+innerHtml+'</a></li>\n';
		}
	}
	suggestItems += '</ul>\n';
	suggestWrap.innerHTML = suggestItems;
	var allItems = $('#'+ul_id+' li');
	
	//mouseover-funktionen und keymarkup vorbereiten
	if(o.isTablet === false){
		allItems.mouseenter(function(){
			$('#'+ul_id+' li.active').removeClass('active');
			$(this).addClass('active');
		}).mouseleave(function(){
			$('#'+ul_id+' li.active').removeClass('active');
			
			// HOTFIX mne: Removed this because the firefox
			// added strange chars to the input field. 
			// everything seems to work fine after I removed this line.
			//aktObj.val(aktObj.data('lastInput'));
		}).click(function(e){
			e.stopPropagation();
			customSubmit(aktObj, o);
		});
	}else{
		allItems.swipe( {
	        tap:function(e, target) {
	        	e.stopPropagation();
	        	// HOTFIX mne: Removed this because the firefox
				// added strange chars to the input field. 
				// everything seems to work fine after I removed this line.
				//aktObj.val($(this).find('a').get(0).rel);
				customSubmit(aktObj, o);
	        },
	        threshold:50
	    });
	}
	
}

function timeoutSubmit(chosenData, aktObj,o){
	closeSuggestWindow(aktObj, 0);
	/*auf wunsch kein submit des formulars, sondern der submitCallback*/
	//aktObj.parents('form').submit();
	if($.isFunction(o.submitCallBack)){
		o.submitCallBack(chosenData);
	}
}

function customSubmit(aktObj, o){ //o sind die settings aus dem plugin
	lock = true;
	var chosenData = aktObj.data('suggestWrapJQ').find('li.active div');
	if(chosenData.length > 0){
		var aktObjVal = $.trim(aktObj.val());
		ira._form.search = aktObjVal;
		var submittime = 0; 
		if(aktObjVal.length == 0){
			return false;
		}else{
			if(o.isTablet === true){
				submittime = o.tabletTime;
			}
			o.submitTimeout = window.setTimeout(function(){timeoutSubmit(chosenData, aktObj, o);},submittime);
		}
	}
}

var methods = {
	init : function(options) {
		return this.each(function() {
			var o = $.extend({}, defaults, options);  //o = settings, bzw defaults
			var aktObj  = $(this); 
			var keyValues	= new Array(9,27,13,38,40); //9 = TAB | 27 = ESC | 13 = ENTER | 38 = Pfeil hoch | 40 = Pfeil runter
			var aktObjPos = aktObj.position();
			reg.push(aktObj);
			var objCount = reg.length;
			var lock = true;
			//den wrapper für die suggests erzeugen und am inputfeld positionieren
			var suggestWrap = createElement('suggestWrap_'+objCount, 'div', 'suggestWrap', aktObj.parents('p').get(0));
			suggestWrap['style']['position'] = 'absolute';
			suggestWrap['style']['left'] = parseInt(aktObjPos.left)+'px';
			suggestWrap['style']['top'] = parseInt(aktObjPos.top) + parseInt(aktObj.outerHeight(true))+'px';
			
			aktObj.data('suggestListId', 'suggestList_'+objCount);
			aktObj.data('suggestWrap', 'suggestWrap_'+objCount);
			aktObj.data('suggestWrapJQ', $('#suggestWrap_'+objCount));
			
			
			if(o.isTablet === true){
				aktObj.data('suggestWrapJQ').swipe( {
			
		        swipe:function(event, direction, distance, duration, fingerCount) {
		        	event.preventDefault();
		        	event.stopPropagation();
		        	var sugwrapScrollTop = aktObj.data('suggestWrapJQ').get(0).scrollTop;
		        	var height = aktObj.data('suggestWrapJQ').height() / 2;
		        	if(direction ==  'up'){
		        	  var scrollTopValueForTablets = 0;
		        	  if(sugwrapScrollTop-height >= 0){
		        		  scrollTopValueForTablets = sugwrapScrollTop-height;
		        	  }
		        	  
		          }else if(direction ==  'down'){
		        	  var scrollTopValueForTablets = sugwrapScrollTop;
		        	  var itemProps =  aktObj.data('itemProps');
		        	  
		        	  if(sugwrapScrollTop+height <= itemProps[itemProps.length-1].sum){
		        		  scrollTopValueForTablets = sugwrapScrollTop+height;
		        	  }else{
		        		  scrollTopValueForTablets += itemProps[itemProps.length-1].sum;
		        	  }
		          }
		        	aktObj.data('suggestWrapJQ').animate({ scrollTop: scrollTopValueForTablets }, 'normal');
		        },
		        threshold:0
		      });
			}
			
			function fetchData(aktObj){
				//debug('fetching');
				//eigener Timestamp, weil der implizit gesendete von jQuery sich unterscheidet
				var ts = Math.round((new Date()).getTime() / 1000);
				aktObj.data('sendTime', ts);	

				$.get(
						process.env.VUE_APP_ROOT_DOMAIN+'/request/geocode',
						{address:aktObj.val()},
						function(res){
							//debug(res);

							if(res.status=='OK'){
								var vlat = res.lat;
								var vlng = res.lng;
								var getParam = {};
								if(ira._sid!=null){
									getParam.SID=ira._sid;
								}
								getParam.lat=vlat;
								getParam.lng=vlng;
								getParam.ts=ts;


								$.get(
									o.ajaxUrl+'/step/contactrequest',
									getParam,
									function(c){
										c = $.parseJSON(c);
										$('#iraForm').css('overflow', 'visible');
										//debug(c);
										prepareSuggestHTML(aktObj, c, o);
										openSuggestWindow(aktObj, o.slideTime, o);
									}
								);
							}

						}
					);				
			}
			
			if(ira._hasValue(ira._form.search)){
				aktObj.val(ira._form.search);
				aktObj.data('lastInput', ira._form.search);
				aktObj.focusin(function(){
					if(aktObj.val().length > 0){
						fetchData(aktObj);
					}
				});
			}
			
			aktObj.keypress(function(e){
				var pressed = (e.keyCode ? e.keyCode : e.which);

				if($.inArray(pressed, keyValues) == -1){
					var inputVal = $.trim(aktObj.val());
					var code = (window.event != undefined && window.event.keyCode != undefined ? window.event.keyCode : pressed);
					if(code != 8){
						inputVal += String.fromCharCode(e.charCode);
					}else{
						inputVal = inputVal.substr(0, inputVal.length-1);
					}	
					aktObj.data('lastInput',inputVal);
					//mindestanzahl an buchstaben erreicht, also ajax abfeuern
					if(inputVal.length >= o.minInput){
						//ajaxCall im Gange? timeout davon clearen!
						if(o.requestTimeout != undefined || o.requestTimeout != null){
                			window.clearTimeout(o.requestTimeout);
                		}
                		o.requestTimeout = window.setTimeout(function(){
                			fetchData(aktObj);
                		}, o.ajaxWait);
					
					}else{
						closeSuggestWindow(aktObj, 0);
					}
				}else{				
					if(pressed == 38){
						toggleKeyMarkUp('up');
						return false;
					}else if(pressed == 40){
						toggleKeyMarkUp('down');
						return false;
					}else if(pressed == 27 || pressed == 9){
						closeSuggestWindow(aktObj, 0);
						if(pressed == 27){
							return false;
						}
						return true;
					}else if(pressed == 13){
						e.preventDefault();
						e.stopPropagation();
						customSubmit(aktObj, o);
						return false;
					}
					return false;
				}
			});
			
			if(navigator.userAgent.match(/MSIE/i) || navigator.userAgent.match(/CHROME/i) || navigator.userAgent.match(/ipad/i)){
				aktObj.keydown(function(e){
					var pressed = e.keyCode;
					if(pressed == 8){
						aktObj.trigger('keypress', e);
					}else if($.inArray(pressed, keyValues) != -1){
						if(pressed == 38){
							toggleKeyMarkUp('up');
							return false;
						}else if(pressed == 40){
							toggleKeyMarkUp('down');
							return false;
						}else if(pressed == 27 || pressed == 9){
							closeSuggestWindow(aktObj, 0);
							if(pressed == 27){
								return false;
							}
							return true;
						}else if(pressed == 13){
							e.preventDefault();
							e.stopPropagation();
							customSubmit(aktObj, o);
							return false;
						}
						return false;
					}
				});
	        }
			
			
			function toggleKeyMarkUp (direction){
				var allElms = null;
				allElms = aktObj.data('suggestWrapJQ').find('li');
				var aktLiObj = aktObj.data('suggestWrapJQ').find('li.active');
				var ssInpObjFound = aktLiObj.length;
				var aktindex = -1;
				var sugWrapHeight = aktObj.data('suggestWrapJQ').height();
				var itemProps = aktObj.data('itemProps');
				var newScrollTop = 0;
				
				aktLiObj.removeClass('active');
				lock = true;
				if(ssInpObjFound == 1){
					aktindex = allElms.index(aktLiObj);
				}
				if(direction == 'up'){
					if(aktindex > 0){
						aktindex--;
					}else{
						aktindex = -1;
					}
					//skip-action beim aktivieren eines Elements außerhalb des sichtbaren bereichs via tastatur
					if(aktindex > -1 && itemProps[aktindex].sum <= aktObj.data('suggestWrapJQ').get(0).scrollTop){
						for(var i=aktindex; i>(aktindex - o.keySlideBlock) && i >= 0; i--){
							newScrollTop += itemProps[i].height;
						}
						if(aktindex - o.keySlideBlock <= 1){
							aktObj.data('suggestWrapJQ').get(0).scrollTop = 0;
						}else{
							aktObj.data('suggestWrapJQ').get(0).scrollTop -= newScrollTop;
						}
						
					}					
					
				}else{
					if(aktindex < allElms.length-1){
			    		aktindex++;
			    	}else{
			    		aktindex = allElms.length-1;
					}
					//skip-action beim aktivieren eines Elements außerhalb des sichtbaren bereichs via tastatur
					if(typeof itemProps !== 'undefined' && 
							typeof itemProps[aktindex] !== 'undefined' && 
							itemProps[aktindex].sum > 
								(sugWrapHeight + aktObj.data('suggestWrapJQ').get(0).scrollTop)){
						for(var i=aktindex; i<(aktindex + o.keySlideBlock) && i <itemProps.length; i++){
							newScrollTop += itemProps[i].height;
						}
						aktObj.data('suggestWrapJQ').get(0).scrollTop += newScrollTop;
					}					
				}
				if(aktindex != -1){
					aktLiObj = $(allElms[aktindex]);
					aktLiObj.addClass('active');
					//aktObj.val(aktLiObj.find('a').attr('rel')).focus();
				}else{
					aktObj.val(aktObj.data('lastInput')).focus();
				}
				
				o.selectCallBack();
				return false;
			}
						
		}); 
	}, //ende init-method
	reset : function(){
		reg = [];
		openSearch = [];
	},
	//getter und setter für defaults
	get : function(key){
		return defaults[key];
	}, //ende get
	
	set : function(key, value){
		defaults[key] = value;
	}//ende set
};

$.fn.suggestedSearchIra = function( method ) {
	if (methods[method]) {
		return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
	} else if (typeof method === 'object' || ! method) {
		return methods.init.apply(this, arguments);
	} else {
		$.error('Method ' + method + ' does not exist in suggestedSearch!');
	}
};
})(jQuery);