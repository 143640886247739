/**
 * Is used to replace all chars of a string with their lowercase values,
 * but also converts german Umlauts like ä, ö, ü and ß
 */
window.mx_toLowerUmlauts=function(str){

    // Remove Umlauts and replace them with placeholders
    str = str
        .replace(/ä|Ä/g, "{{ae}}")
        .replace(/ö|Ö/g, "{{oe}}")
        .replace(/ü|Ü/g, "{{ue}}")
        .replace(/ß/g, "{{ss}}");

    // Convert all chars to lowercase
    str = str.toLowerCase();

    // Reinsert the umlauts
    str = str
        .replace(/{{ae}}/g, "\u00E4")
        .replace(/{{oe}}/g, "\u00F6")
        .replace(/{{ue}}/g, "\u00FC")
        .replace(/{{ss}}/g, "\u00DF");

    // Return formated string
    return str;
}

/**
 * Converts the first char of the given String to uppercase.
 * test test test will be: Test test test
 */
window.mx_firstToCapsUmlauts=function(str){

    // Skip if we did not get a string
    if(typeof str !== 'string') return '';

    // Get first char
    let char_first = str.charAt(0);

    // Replace ulauts
    if(char_first === 'ä' || char_first === 'Ä'){
        char_first = "\u00C4";
    } else if(char_first === 'ö' || char_first === 'Ö'){
        char_first = "\u00C4";
    } else if(char_first === 'ü' || char_first === 'Ü'){
        char_first = "\u00C4";
    } else if(char_first === 'ß'){
        char_first = "\u00C4";
    } else {
        char_first = char_first.toUpperCase();
    }

    // Return result
    return char_first + str.slice(1);
}

window.rawurldecode=function(str) {
    //       discuss at: http://phpjs.org/functions/rawurldecode/
    //      original by: Brett Zamir (http://brett-zamir.me)
    //         input by: travc
    //         input by: Brett Zamir (http://brett-zamir.me)
    //         input by: Ratheous
    //         input by: lovio
    //      bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // reimplemented by: Brett Zamir (http://brett-zamir.me)
    //      improved by: Brett Zamir (http://brett-zamir.me)
    //             note: Please be aware that this function expects to decode from UTF-8 encoded strings, as found on
    //             note: pages served as UTF-8
    //        example 1: rawurldecode('Kevin+van+Zonneveld%21');
    //        returns 1: 'Kevin+van+Zonneveld!'
    //        example 2: rawurldecode('http%3A%2F%2Fkevin.vanzonneveld.net%2F');
    //        returns 2: 'http://kevin.vanzonneveld.net/'
    //        example 3: rawurldecode('http%3A%2F%2Fwww.google.nl%2Fsearch%3Fq%3Dphp.js%26ie%3Dutf-8%26oe%3Dutf-8%26aq%3Dt%26rls%3Dcom.ubuntu%3Aen-US%3Aunofficial%26client%3Dfirefox-a');
    //        returns 3: 'http://www.google.nl/search?q=php.js&ie=utf-8&oe=utf-8&aq=t&rls=com.ubuntu:en-US:unofficial&client=firefox-a'

    return decodeURIComponent((str + '')
        .replace(/%(?![\da-f]{2})/gi, function() {
            // PHP tolerates poorly formed escape sequences
            return '%25';
        }));
}

window.mx_eTracker_wrapper=function (
    EtArea,
    mxet_target,
    eTrackerKeyWA,
    eTrackerKeyCC,
    type,
    mxet_target_suffix){

    eTrackerKeyWA=process.env.VUE_APP_ETRACKER_KEY
    eTrackerKeyCC=process.env.VUE_APP_ETRACKER_KEY_A2

    // Config
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Set to true to see debug messages
    let is_debug = false;
    // Set this to true to don't send anything
    let is_simulation = false;
    // Set this to true to disable etracker user session limitations
    // NOTE: THIS IS FOR TESTING ONLY!
    let is_disjunkt = false;

    // Type Definitions
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    let is_viewProduct = false;
    let is_confirm = false;
    let is_newsletter = false;
    let is_onlyNewsletter = false;
    let append_confirm = true;
    let append_newsletter = true;
    let use_wrapper = true;

    // Select values
    //debug(type);
    switch(type){

        case 'ira/theme':
        case 'ira_contact/contact_zentrale_allgemein':
        case 'ira_contact/callback':
        case 'ira_contact/contact_zentrale':
        case 'ira_contact/contact':
        case 'ira_contact/contact_allgemein':
            is_viewProduct = true;
            is_confirm = false;
            is_newsletter = false;
            break;

        case 'ira/submit':
        case 'ira_contact/contactsend':
        case 'ira_contact/callbacksend':
        case 'ira_contact/contactsend_allgemein':
            is_viewProduct = false;
            is_confirm = true;
            is_newsletter = false;
            break;

        case 'AllgemeinerNewsletter':
            is_onlyNewsletter = true;
            use_wrapper = false;

        case 'ira/submit+Newsletter':
        case 'ira_contact/contactsend+Newsletter':
        case 'ira_contact/contactsend_allgemein+Newsletter':
            is_viewProduct = false;
            is_confirm = true;
            is_newsletter = true;
            break;

        case 'ira/step':
            is_viewProduct = false;
            is_confirm = false;
            is_newsletter = false;
            break;

        case 'Gewinnspiel/danke':
            is_viewProduct = false;
            is_confirm = false;
            is_newsletter = false;
            use_wrapper = false;
            break;

        case 'Gewinnspiel/danke+Newsletter':
            is_onlyNewsletter = true;
            is_viewProduct = false;
            is_confirm = true;
            is_newsletter = false;
            use_wrapper = false;
            break;

        case 'Zentrale_Gewinnspiel-Newsletter':
            is_onlyNewsletter = true;
            is_viewProduct = false;
            is_confirm = true;
            is_newsletter = false;
            use_wrapper = false;
            break;

        case 'Partner_Gewinnspiel-Newsletter':
            is_onlyNewsletter = true;
            is_viewProduct = false;
            is_confirm = true;
            is_newsletter = false;
            use_wrapper = false;
            break;

        default:
            console.error('Type: ' + type + ' was not found!');
            return false;
            break;
    }


    // Build helper functions
    let methods = {
        /**
         * Capitalizes all parts of a string, which are separated via an underscore
         * test_test_test will be Test_Test_Test
         * @param string str
         */
        'capitalizeTarget': function(str){

            // Split string into pieces
            let list_str = str.split('_');

            // Run trough all parts
            for(let i = 0; i < list_str.length; i++){
                list_str[i] = mx_firstToCapsUmlauts(list_str[i]);
            }

            // Combine the string back together
            return list_str.join('_');
        }
    };

    // Prepare wrapper data
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    mxet_target = methods.capitalizeTarget(mxet_target);
    let wa_target = mxet_target;
    wa_target += typeof(mxet_target_suffix) !== 'undefined' ? mxet_target_suffix : '';
    wa_target.replace(/\+/g,"-");
    let wa_area = rawurldecode(methods.capitalizeTarget(EtArea));

    // Special rules for confirm pages
    if(is_confirm && append_confirm){
        wa_target += '/Bestätigung';
    }
    // Special rules for newsletter submission
    if(is_newsletter && append_newsletter){
        wa_target += '-Newsletter';
    }

    // Build pagename
    let wa_pagename = cc_pagename = mx_toLowerUmlauts(wa_target);

    // Build eCommerce page id
    let ec_pageid = mx_toLowerUmlauts(mxet_target).replace(/ /g,"_");

    // Call eTracker wrappers
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    if(is_simulation !== true && use_wrapper === true){
        // Call old Wrapper
        if(typeof et_eC_Wrapper !== 'undefined'){
            et_eC_Wrapper(eTrackerKeyWA, wa_pagename, wa_area, 0, "", (is_disjunkt ? '__DISJUNKT__' : '' ) + wa_target, 0, 0, 0, 0, 0, 0, 0);
        }else{
            console.error(
                "Could not load et_eC_Wrapper width variables:\n"+
                "pagename: "+wa_pagename+"\n"+
                "area: "+wa_area+"\n"+
                "target: "+wa_target
            )
        }


        // Call new Wrapper
        /*
        if(typeof et_cc_wrapper !== 'undefined'){
            et_cc_wrapper(eTrackerKeyCC, { 'cc_pagename': cc_pagename });
        }
        */

    }

    // Mark "product" viewed by eTracker
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Check if the current type counts as product
    if(is_viewProduct){
        // Prepare Product object
        let viewProduct =
            { 'id' : ec_pageid, 'name': mxet_target, 'category': ['Contact from'],
                'price': '0', 'currency': 'EUR', 'variants': {}};

        // Check if we can send our product
        if(typeof etCommerce !== 'undefined' && is_simulation === false){
            // Send event
            etCommerce.sendEvent('viewProduct', viewProduct);
        }
    }

    // Mark "product" purchased by eTracker
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    if(is_confirm){
        // Get Date
        let currentDate = new Date();

        // Create random id
        let order_id = Math.floor(Date.now() / 1000) + Math.floor(Math.random() * 9999) +
            '_'
            + ("0" + currentDate.getHours()).substr(-2) + ':'
            + ("0" + currentDate.getMinutes()).substr(-2) + ':'
            + currentDate.getSeconds();

        let list_product = [];

        // Prepare product array
        if(is_onlyNewsletter === false){
            list_product.push(
                {
                    'product': {
                        'id': ec_pageid + '_submit', 'name': mxet_target, 'category': ['Contact from'],
                        'price' : '0', 'currency': 'EUR', 'letiants' : {}
                    },
                    'quantity': 1
                }
            );
        }

        // Append Newsletter as product if it is selected
        if(is_newsletter === true || is_onlyNewsletter === true){
            list_product.push({
                'product': {
                    'id': ec_pageid + '_newsletter', 'name': mxet_target + ' Newsletter',
                    'category': ['Contact from', 'Newsletter'], 'price': '0', 'currency': 'EUR', 'variants': {}
                },
                'quantity': 1
            });
        }

        // Build order
        let orderProduct = {
            'orderNumber': order_id,
            'status': 'lead',
            'orderPrice': '1',
            'currency': 'EUR',
            'basket': {
                'id': order_id + '_basket',
                'products': list_product
            }
        };


        // Check if we can send our product
        if(typeof etCommerce !== 'undefined' && is_simulation === false){
            // Send event to eTracker
            etCommerce.sendEvent('order', orderProduct);

            // Call saleTrack
            //saleTrack.addSaleItem({itemcount:1, itemvalue:0, m1:wa_target, m2:wa_area});
            //saleTrack.logSale(1);
        }
    }

    // Send debug
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    if(is_debug){
        // Object rendering helper
        let renderer_object = function(object, layer){
            if(typeof(layer) === 'undefined') layer = 1;
            let arr = [];
            for (let i = 0; i < layer; i++) {
                arr.push('       ');
            }
            let prefix = arr.join("");
            let result = '';
            for (let key in object) {
                if (object.hasOwnProperty(key)) {
                    let prop = object[key];
                    // Recursive if we found an object
                    if(typeof prop === 'object'){
                        prop =  "\n" + renderer_object(prop, layer + 1);
                    } else {
                        prop += "\n";
                    }
                    result += prefix + key + ': ' + prop;
                }
            }
            return result;
        };

        console.log(
            "\n" + 'eTracker Debugging:' + "\n" +
            '----------------------------------------' + "\n" +
            'Type: ' + type + "\n" +
            '     ' + 'is_viewProduct: ' + is_viewProduct + "\n" +
            '     ' + 'is_confirm: ' + is_confirm + "\n" +
            '     ' + 'is_newsletter: ' + is_newsletter + "\n" +
            '     ' + 'is_onlyNewsletter: ' + is_onlyNewsletter + "\n" +
            '     ' + 'append_confirm: ' + append_confirm + "\n" +
            '     ' + 'append_newsletter: ' + append_newsletter + "\n\n" +
            'Web Analytics:' + "\n" +
            '     ' + 'SUBMITTED: ' + (use_wrapper ? 'Yes' : 'No') + "\n" +
            '     ' + 'key: ' + eTrackerKeyWA + "\n" +
            '     ' + 'pagename: ' + wa_pagename + "\n" +
            '     ' + 'areas: ' + wa_area + "\n" +
            '     ' + 'target: ' + (is_disjunkt ? '__DISJUNKT__' : '' ) + wa_target + "\n\n" +
            'Campaign Control:' + "\n" +
            '     ' + 'SUBMITTED: ' + (use_wrapper ? 'Yes' : 'No') + "\n" +
            '     ' + 'Key: ' + eTrackerKeyCC + "\n" +
            '     ' + 'cc_pagename: ' + cc_pagename + "\n" +
            '     ' + 'cc_attributes: ' + (typeof window.cc_attributes !== 'undefined' ?
                "\n" + renderer_object(window.cc_attributes) : '') + "\n" +
            'eCommerce:' + "\n" +
            '     ' + 'SUBMITTED: ' + (is_confirm || is_viewProduct ? 'Yes' : 'No') + "\n" +
            '     ' + 'ec_pageid: ' + ec_pageid + "\n" +
            '     ' + 'viewProduct: ' + (typeof(viewProduct) !== 'undefined' ?
                "\n" + renderer_object(viewProduct) : '') + "\n" +
            '     ' + 'orderProduct: ' + (typeof(orderProduct) !== 'undefined' ?
                "\n" + renderer_object(orderProduct) : "\n") + "\n" +
            'Sale Track:' + "\n" +
            '     ' + 'SUBMITTED: ' + (is_confirm ? 'Yes' : 'No') + "\n" +
            '     ' + 'm1: ' + wa_target + "\n" +
            '     ' + 'm2: ' + wa_area + "\n\n" +
            'Raw:' + "\n" +
            '     ' + 'EtArea: ' + EtArea + "\n" +
            '     ' + 'mxet_target: ' + mxet_target + "\n" +
            '     ' + 'eTrackerKeyWA: ' + eTrackerKeyWA + "\n" +
            '     ' + 'eTrackerKeyCC: ' + eTrackerKeyCC + "\n" +
            '     ' + 'type: ' + type + "\n" +
            '     ' + 'mxet_target_suffix: ' + mxet_target_suffix + "\n\n" +
            'Misc:' + "\n" +
            '     ' + 'href: ' + window.location.href + "\n" +
            '     ' + 'is_simulation: ' + is_simulation + "\n" +
            '     ' + 'is_disjunkt: ' + is_disjunkt + "\n"
        );
    }
}
