import { createApp } from 'vue'
import VueSmoothScroll from 'vue3-smooth-scroll'

import centralHeader from "./components/central-header.vue";
import centralFooter from "./components/central-footer.vue";
import partnerHeader from "./components/partner-header.vue";
import partnerFooter from "./components/partner-footer.vue";

import fonts from "./sass/fonts.scss"
import styles from "./sass/styles.scss"
import stylesAdac from "./sass/styles_adac.scss"

import ira from './javascript/ira'
window.ira=ira;

import mobileAction from './javascript/mobileAction'
window.mobileAction=mobileAction;

require('./javascript/GeneralJS')

if (process.env.NODE_ENV==='development'){
    window.et_areas=''
}

const App=createApp({
    components:{
        centralHeader, centralFooter, partnerHeader, partnerFooter
    },
    data() {
        return {
            taId:null,
            config:null
        }
    },
    methods:{
        setConfig(data){
            sessionStorage.setItem('DpSid',data.sid)
            if (data.tenant.usercentricsId){
                let u=document.createElement('script')
                u.id='usercentrics-cmp'
                u.src='https://app.usercentrics.eu/browser-ui/latest/loader.js'
                u.dataset.settingsId=data.tenant.usercentricsId
                u.async=true

                let p=document.createElement('script')
                p.type='application/javascript'
                p.src='https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js'

                document.head.appendChild(u)
                document.head.appendChild(p)
            }

            if (data.tenant.isAdac){
                window.metaThemeColor.content='#ffcc00'
                document.body.classList.add('adac')
            }

            let aliasUrl=data.tenant.alias?'/'+data.tenant.alias:''

            let iraconfig = {
                "mainurl":process.env.VUE_APP_ROOT_DOMAIN,
                "url":process.env.VUE_APP_ROOT_DOMAIN+aliasUrl+"/ira",
                "urlContact":process.env.VUE_APP_ROOT_DOMAIN+aliasUrl+"/iracontact",
                "ready":true
            };
            let myurl = $.extend({}, {url:window.location.href});
            iraconfig.urlCaller=myurl.url;
            iraconfig.titleCaller=document.title;
            ira._create(iraconfig);

            this.config=data
            window.website.classList.add('mounted')
        },
        getConfig(){
            const p = new URLSearchParams(window.location.search)
            this.taId=p.get('taid')??'DP1000';
            if(localStorage && process.env.VUE_APP_USE_CACHE!='false'){
                let config=localStorage.getItem(this.taId)
                if (config){
                    this.setConfig(config)
                }else{
                    this.fetchConfig()
                }
            }else{
                this.fetchConfig()
            }
        },
        fetchConfig() {
            let url = process.env.VUE_APP_ROOT_API + '/config/' + this.taId;
            let sid = sessionStorage.getItem('DpSid')
            if (sid){
                url+='?SID='+sid
            }
            fetch(url)
                .then(res=>res.json())
                .then(data=>{
                    this.setConfig(data)
                })
        },
        linkHref(item){
            let href=typeof item==='string'?item:item.cP;
            if (href==='{{contact##allgemein}}'){
                href='javascript:'+this.contactLink()
            } else if (href.substring(0,1)==='/'){
                href=process.env.VUE_APP_ROOT_DOMAIN+href
                let sid = sessionStorage.getItem('DpSid')
                if (sid){
                    href+='?SID='+sid
                }
            }
            return href
        },
        linkTarget(item){
            return item.t??'_self'
        },
        linkId(ns,item){
            return ns+item.p
        },
        toggleThemeMore(e){
            e.stopPropagation()
            const link=document.querySelector('#nav_themennav')
            const wrapper=document.querySelector('#nav_theme_wrap')
            const inner=wrapper.querySelector('#nav_theme_list')
            const height=(inner.offsetHeight+20)+'px'
            if(wrapper.style.height!==height){
                wrapper.style.height=height
                link.classList.add('active')
            }else{
                this.closeThemeMore()
            }
        },
        closeThemeMore(){
            const link=document.querySelector('#nav_themennav')
            const wrapper=document.querySelector('#nav_theme_wrap')
            wrapper.style.height=0;
            link.classList.remove('active')
        },
        toggleMobileNav(){
            this.mobileNavOpen=!this.mobileNavOpen
            const mainnav=document.querySelector('#mainnav')
            const mobilenav=document.querySelector('#mobilenav')
            const mainnav_mobile=document.querySelector('#mainnav_mobile')
            if (this.mobileNavOpen){
                mainnav.classList.add('mobopen')
                mobilenav.classList.add('open')
                mainnav_mobile.style.display='block'
            }else{
                this.closeMobileNav()
            }
        },
        closeMobileNav(){
            this.mobileNavOpen=false
            const mainnav=document.querySelector('#mainnav')
            const mobilenav=document.querySelector('#mobilenav')
            const mainnav_mobile=document.querySelector('#mainnav_mobile')
            mainnav.classList.remove('mobopen')
            mobilenav.classList.remove('open')
            mainnav_mobile.style.display='none'
        },
        iraOpen(t,o={}){
            o.mid=this.config.tenant.id;
            if (t==='ira'){
                ira._open(o)
            }else if (t==='contact'){
                ira._openContact(o)
            }else if (t==='callback'){
                ira._openCallback(o)
            }
        },
        flyoutLink(pos){
            return 'ira._iraflyout(this,{mid:'+this.config.tenant.id+'},'+pos+')'
        },
        iraLink(){
            return 'ira._open({mid:'+this.config.tenant.id+',channel:"allgemein"})'
        },
        contactLink(){
            return 'ira._openContact({mid:'+this.config.tenant.id+',channel:"allgemein"})'
        },
        callbackLink(){
            return 'ira._openCallback({mid:'+this.config.tenant.id+',channel:"allgemein"})'
        },
        phoneNumberHref(phone){
            let number=phone.replace(/[^\d]/g, "");
            if (number.substring(0,2)==='00'){
                number='+'+number.substring(3)
            }else if (number.substring(0,1)==='0'){
                number='+49'+number.substring(1)
            }
            return 'tel:'+number
        },
        phoneNumberLabel(phone){
            return phone
        },
    },
    computed:{
        isCentral(){
            return this.config&&this.config.tenant.id===1000;
        },
        isPartner(){
            return this.config&&this.config.tenant.id!==1000;
        },
        isPhone(){
            return !!this.config.tenant.phone
        },
        isFax(){
            return !!this.config.tenant.fax
        },
        phoneHref(){
            return this.phoneNumberHref(this.config.tenant.phone)
        },
        phoneLabel(){
            return this.phoneNumberLabel(this.config.tenant.phone)
        },
        faxHref(){
            return this.phoneNumberHref(this.config.tenant.fax)
        },
        faxLabel(){
            return this.phoneNumberLabel(this.config.tenant.fax)
        },
        dtsDomain(){
            return process.env.VUE_APP_DTS_HREF+'/'+this.config.tenant.btAlias
        },
        dtsHref(){
            return this.dtsDomain
        },
    },
    async mounted() {
        await this.getConfig()

        if (window.$===undefined){
            window.$ = window.jQuery = require('jquery');
        }

        window.TimeSettings=[];

        require('jstorage')
        require('jquery.scrollto')
        require('./javascript/select2')
        require('./javascript/jqueryui')
        require('./javascript/jqueryui.starselect')
        require('./javascript/jquery.mx-suggestedSearchIra')

        await mobileAction.init()

        if(mobileAction.win.width()>mobileAction.breakpoint){
            self.status='d';
            $.event.trigger({
                type : 'loaddesktop'
            });
        }else{
            self.status='m';
            $.event.trigger({
                type : 'loadmobile'
            });
        }

        document.addEventListener('click',e=>{
            this.closeThemeMore()
        })
        window.addEventListener('resize',e=>{
            this.closeMobileNav()
        })
    },
})
App.use(VueSmoothScroll)
App.mount('#website')
